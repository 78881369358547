import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
function PrivacyPolicy() {
  return (
   <>
   <Helmet>
        <title>Your Privacy Matters | Privacy Policy  </title>
        <meta name="description" content="We prioritize and protect your privacy at Indian Armour. Our Privacy Policy outlines the measures we take to protect your information. Understand our commitment to transparency and security." />
        <link rel="canonical" href="https://www.indianarmour.com/privacy-policy" />
      </Helmet>
    <section className="inner-banner d-flex align-items-center justify-content-center" style={{ backgroundImage: 'url("/images/About_Us_Banner.png")' }}>
                <div className="inner-banner-content1">
                    <div className="container">
                        <h1 className="text-white fw-bolder">Privacy Policy</h1>
                    </div>
                </div>
            </section>

            <section className="breadcrumb-inner pt-3  pb-5">
                <div className="container">
                    <nav >
                        <ol className="breadcrumb mb-0 justify-content-start">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                        </ol>
                    </nav>
                </div>
            </section>
            <section className='page-content pb-5'>
                <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <p>Indian Armour Systems Pvt. Ltd. respects your privacy. You can browse the website without disclosing your identity or any personal information. We are, however, able to determine your IP address. It helps us to diagnose problems with our server, to regulate our website, to evaluate the use of our website and to ameliorate the content of our site. In brief, your IP is used to muster aggregate demographic information. There is only a contact us page where we ask you to provide us your personal information. The types of personal information collected at these pages are: Name, Address, Email address, Phone number and company name (etc.).</p>
                        <p>The Way We Use Information: <br />We may use your personal information for particular purposes such as detail of your interest or inquiry for our products or for marketing purposes to provide you with the latest INDIAN ARMOUR  products and promotional information and other information that might be useful to you. Information provided by you on our website will not be used unless you fill out the inquiry form. We may use the information to monitor guest traffic and site usages to help us develop and design the layouts of our websites.</p>
                        <p>As you browse <Link to="/">www.indianarmour.com</Link>  and other websites, online ad networks we work with may place anonymous cookies on your computer, and use similar technologies, in order to understand your interests based on your online activities, and thus to tailor more relevant ads to you.</p>
                        <p>We reveal your personal information only if we believe it is necessary to comply with the law, enforce or apply our Terms and Conditions or protect the rights, property, or safety of INDIAN ARMOUR, our users, or others. <br />
                        Any page that asks you for personal information is a secure page. These pages employ state of the art encryption to protect your personal information from unauthorized use.</p>
                        <p>From time to time, INDIAN ARMOUR reserves the right to change our privacy policy. We will notify our customers of such changes on our website. By using this website you are agreeing to the terms of our Privacy Policy.</p>

                    </div>
                    </div>
                </div>
            </section>
   </>
  )
}

export default PrivacyPolicy
import React from 'react'
 
import ProductDetail from "../../component/ProductDetail";
import ProductFAQ from '../../component/ProductFAQ';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function TacticalGears() {
    
  const location = useLocation();
 
  const slug = location.pathname.split("/")[2];
  
  return ( 
    <div>
           <Helmet>
        <title>Tactical Gears: Equip Yourself with Premium Tactical Equipment</title>
        <meta name="description" content="Browse our collection of Tactical Gears and gear up with premium-quality equipment designed to meet the demands of tactical professionals." />
        <link rel="canonical" href="https://www.indianarmour.com/tactical-gears" />
      </Helmet>
        <section className="inner-banner2 " style={{backgroundImage: 'url("/images/tactical_gear_banner.jpg")'}}>
          <div className="inner-banner2-content d-flex align-items-center">
            <div className="container">
              <h1 className=" fw-bolder">Tactical Gears</h1>
              <strong>Gear up for ultimate protection </strong>
            </div>
          </div>
        </section>
        <section className="breadcrumb-inner pt-3  pb-5">
          <div className="container">
            <nav >
              <ol className="breadcrumb mb-0 justify-content-start">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Tactical Gears</li>
              </ol>
            </nav>
          </div>
        </section>
        {/* Ultimate Ballistic Protection */}
        <section className="ballistic-protection pb-0">
          <div className="container">
            <div className="row">
              <div className="protection-he">
                <h2>Tactical Gears by Indian Armour</h2>
                 <h3>Your Tactical Partner in Every Mission</h3>
                <p>Precision, durability, and reliability are essential components of tactical operations. At Indian Armour, we understand the needs of customers who desire tactical equipment that excels in high-stakes situations. Our meticulously crafted range of tactical gear is made to meet the standards of the military, law enforcement, and adventure lovers. Our product line's technical superiority makes sure you are prepared for any mission.</p>
              </div>
            </div>
        
          </div>
        </section>
        {/* Ultimate Ballistic Protection */}
        {/* Ballistic Body Armour */}
        <section className="ballistic-armour tactical-gear">
          <div className="container">
            {/* <div className="protection-he">
              <h2><strong>Tactical Trousers:</strong> Tread Confidently </h2>
              <p>Our tactical trousers are designed with state-of-the-art materials that offer great durability without compromising mobility. Multiple pockets and reinforced stitching guarantee you have easy access to necessary tools and equipment, while the fabric's moisture-wicking qualities keep you comfortable in any environment. </p>
            </div> */}
            
            {/* ballistic-armour-box */}
            <div className="ballistic-armour-box">
              <div className="row reverse">
                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-contant" data-aos="fade-right" data-aos-offset={100}>
                    <div className="ballistic-armour-box-text2">
                      <h2><strong>Tactical Belt:</strong> Built to Bear the Load</h2>
                      <p>An essential part of your loadout is the Indian Armour Tactical Belt. It features safe attachment points for holsters, pouches, and more and is built for outstanding load-bearing capacity. Its fast-release mechanism ensures swift adjustment in dynamic circumstances.</p>
                      <div className="input-group mt-3">
                    <Link to="/product/tactical-belt" className="btn know-more-btn submit">View Product
                        <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                      </Link>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-img2" data-aos="fade-left" data-aos-offset={100}>
                    <img src="/images/TacticalBeltBuilttoBeartheLoad.png" className='img-fluid' alt='tactical belt'/>
                  </div>
                </div>
              </div>
            </div>
            {/* ballistic-armour-box */}
            {/* ballistic-armour-box */}
            <div className="ballistic-armour-box">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-img" data-aos="fade-right" data-aos-offset={100}>
                    <img src="/images/TacticalTrousersTreadConfidently.png" className='img-fluid' alt='tactical trousers' />
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-contant" data-aos="fade-left" data-aos-offset={100}>
                    <div className="ballistic-armour-box-text">
                      <h2><strong>Tactical Trousers:</strong> Tread Confidently </h2>
                      <p> Our tactical trousers are designed with state-of-the-art materials that offer great durability without compromising mobility. Multiple pockets and reinforced stitching guarantee you have easy access to necessary tools and equipment, while the fabric's moisture-wicking qualities keep you comfortable in any environment. </p>
                      <div className="input-group mt-3">
                    <Link to="/product/tactical-trousers" className="btn know-more-btn submit text-white">View Product
                        <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                      </Link>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ballistic-armour-box */}
            {/* ballistic-armour-box */}
            <div className="ballistic-armour-box">
              <div className="row reverse">
                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-contant" data-aos="fade-right" data-aos-offset={100}>
                    <div className="ballistic-armour-box-text2">
                      <h2><strong>Military Shoes:</strong>  Every Step Matters</h2>
                      <p>Our military footwear is designed to survive the most difficult terrain. They offer stability and comfort with their reinforced soles and ankle support, allowing you to focus on your task without being distracted by tired feet. </p>
                      <div className="input-group mt-3">
                    <Link to="/product/military-boots" className="btn know-more-btn submit">View Product
                        <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                      </Link>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-img2" data-aos="fade-left" data-aos-offset={100}>
                    <img src="/images/MilitaryShoes_ EveryStepMatters.png" className='img-fluid' alt='military shoes'/>
                  </div>
                </div>
              </div>
            </div>
            {/* ballistic-armour-box */}
            {/* ballistic-armour-box */}
            <div className="ballistic-armour-box">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-img" data-aos="fade-right" data-aos-offset={100}>
                    <img src="/images/Knee-Pads-Elbow-Pads-Protection-in-Motion.png" className='img-fluid' alt='knee pads & elbow pads'/>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-contant" data-aos="fade-left" data-aos-offset={100}>
                    <div className="ballistic-armour-box-text">
                      <h2><strong>Knee Pads & Elbow Pads:</strong>  Protection in Motion </h2>
                      <p>Our knee and elbow pads become your trusted partners, especially in situations where agility is needed. They are designed with impact-resistant materials to provide better joint protection without obstructing fluid movement, allowing you to carry out your duty with assurance and safety. </p>
                      <div className="input-group mt-3">
                    <Link to="/product/tactical-pads" className="btn know-more-btn submit">View Product
                        <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                      </Link>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ballistic-armour-box */}
            {/* ballistic-armour-box */}
            <div className="ballistic-armour-box">
              <div className="row reverse">
                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-contant" data-aos="fade-right" data-aos-offset={100}>
                    <div className="ballistic-armour-box-text2">
                      <h2><strong>Tactical Pouches:</strong>  Organised Access to Essentials</h2>
                      <p>The tactical pouches from Indian Armour provide a simple and organized method for storing equipment. They are made to effectively fit mission-specific equipment effectively. You can easily attach MOLLE-compatible tools to belts and vests, allowing you to keep your most important equipment within quick reach while keeping your gear organised.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-img2" data-aos="fade-left" data-aos-offset={100}>
                    <img src="/images/TacticalPouchesOrganisedAccesstoEssentials.png" className='img-fluid' alt='tactical pouches' />
                  </div>
                </div>
              </div>
            </div>
            {/* ballistic-armour-box */}
              {/* ballistic-armour-box */}
              <div className="ballistic-armour-box">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-img" data-aos="fade-right" data-aos-offset={100}>
                    <img src="/images/GunHolsterDrawwithConfidence.png" className='img-fluid' alt='gun holster'/>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-contant" data-aos="fade-left" data-aos-offset={100}>
                    <div className="ballistic-armour-box-text">
                      <h2><strong>Gun Holster:</strong>  Draw with Confidence  </h2>
                      <p>Indian Armour's gun holster is designed for speed and precision. When you need your firearm the most, it will always be accessible thanks to its improved retention and secure fit. It is the pinnacle of firearm carry solutions, customised for different firearm models. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ballistic-armour-box */}
            {/* ballistic-armour-box */}
            <div className="ballistic-armour-box">
              <div className="row reverse">
                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-contant" data-aos="fade-right" data-aos-offset={100}>
                    <div className="ballistic-armour-box-text2">
                      <h2><strong>Tactical Kit:</strong>   Gear Up Efficiently </h2>
                      <p>Our tactical kit streamlines your loadout by combining necessary gear components into a single, mission-specific package. Every component, from magazine holders to utility pouches, has been carefully engineered to maximise efficiency and preparedness in the field and ensure that you are ready for any situation.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-img2" data-aos="fade-left" data-aos-offset={100}>
                    <img src="/images/TacticalKitGearUpEfficiently.png" className='img-fluid'  alt='tactical kit'/>
                  </div>
                </div>
              </div>
            </div>
            {/* ballistic-armour-box */}
              {/* ballistic-armour-box */}
              <div className="ballistic-armour-box">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-img" data-aos="fade-right" data-aos-offset={100}>
                    <img src="/images/Rucksack-100-Litre.png" className='img-fluid' alt='rucksack 100 litre' />
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-contant" data-aos="fade-left" data-aos-offset={100}>
                    <div className="ballistic-armour-box-text">
                      <h2><strong>Rucksack 100 Litre: </strong> Pack it All  </h2>
                      <p>For extended missions, the 100-litre rucksack is your ideal travel partner. For ultimate comfort, it includes various compartments, reinforced straps, and an ergonomic design. You can carry everything you need for lengthy deployments with no compromises thanks to their tough structure.  </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ballistic-armour-box */}
            {/* ballistic-armour-box */}
            <div className="ballistic-armour-box">
              <div className="row reverse">
                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-contant" data-aos="fade-right" data-aos-offset={100}>
                    <div className="ballistic-armour-box-text2">
                      <h2><strong>Tactical Backpack 60 Litre:</strong>    Streamlined Versatility  </h2>
                      <p>Our 60-litre tactical backpack strikes the right combination of versatility and agility. Essential functions aren't compromised despite its compact design. It is the best option for dynamic operations when efficiency is crucial because it has fast access compartments, hydration compatibility, and MOLLE webbing.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                  <div className="ballistic-armour-box-img2" data-aos="fade-left" data-aos-offset={100}>
                    <img src="/images/TacticalBackpack60LitreStreamlinedVersatility.png" className='img-fluid' alt='tactical backpack 60'/>
                  </div>
                </div>
              </div>
            </div>
            {/* ballistic-armour-box */}
          </div>
        </section>
        {/* Ballistic Body Armour */}

        <section className='why-us-blk'>
          <div className='container'>
            <div className='col-lg-12 text-center'>
               <h2 className='heading'>Why Choose Indian Armour </h2>
               <h3>Reliable Tactical Solutions</h3>
               <p>Your greatest allies in the arena of tactical operations are precision and reliability. The range of tactical equipment from Indian Armour is meticulously crafted to meet and exceed your expectations. Our technical superiority ensures that you are prepared for every mission on the field and in the front lines. If performance and precision are your priorities, choosing Indian Armour is your way to go.</p>
            </div>
          </div>
        </section>
      
        {/* faq */}
        <section className="faq-sec">
          <div className="container">
            <div className="faq-he">
              <h2>Frequently asked questions</h2>
            </div>
            <div className="row">
              <div className="faq-accordion">
                <div className="row">
                  <div className="col-lg-6" data-aos="fade-right" data-aos-offset={300}>
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          What are tactical gears used for?
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{}}>
                          <div className="accordion-body">
                            <p>In the military, law enforcement, and outdoor activities, tactical gears are used to improve operational capabilities. They consists of specialised clothing and equipment made to maximise protection, mobility, and functionality for missions. Their applications range from load carrying to weapon retention to environmental adaptation.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Can civilians wear tactical gear?
                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>
                            Civilians can wear tactical gear for a variety of purposes. Due to its robustness and functional design, tactical equipment is frequently used by civilians for outdoor pursuits like hiking, camping, and shooting sports. This enhances their preparedness and safety.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading3">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseTwo">
                          Why do people wear tactical trousers?
                          </button>
                        </h2>
                        <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>
                            To enable greater mobility, easy access to necessary equipment, and adaptability for a variety of activities, including military operations and outdoor pursuits, people wear tactical trousers. They offer improved durability, several pockets for gear storage, and comfort-enhancing moisture-wicking characteristics. 
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading4">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseTwo">
                          What are tactical trousers made from?
                          </button>
                        </h2>
                        <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>
                            The materials used to make tactical trousers are advanced synthetic materials like nylon, polyester, and spandex. These materials have desirable qualities like durability, moisture-wicking properties, and resistance to wear and tear. 
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading5">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapseTwo">
                          Are tactical belts worth it?
                          </button>
                        </h2>
                        <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>
                            Due to their numerous uses, tactical belts are certainly worth it. They accommodate holsters, pouches, and accessories and offer secure load-bearing capacities. They enhance mission adaptability, due to their quick-release mechanisms and configurable features. 
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading6">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapseTwo">
                          What is a tactical belt used for?
                          </button>
                        </h2>
                        <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>
                            A tactical belt is used to offer crucial load-bearing support for equipment used in diverse operations. It is made to safely carry holsters, pouches, and other attachments, making it easier to quickly access mission-specific equipment. The role of the tactical belt is crucial in evenly dispersing the weight of necessary equipment for operational readiness.
                            </p>
                          </div>
                        </div>
                      </div>





                    </div>
                  </div>
                  {/* /col-lg-6 */}
                  <div className="col-lg-6" data-aos="fade-left" data-aos-offset={300}>
                    <div className="accordion" id="accordionExample1">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne1-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1-1" aria-expanded="true" aria-controls="collapseOne1-1">
                          Can I wear military shoes?
                          </button>
                        </h2>
                        <div id="collapseOne1-1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                            Civilians can also don military footwear. These specialised footwear are supportive and durable, making them appropriate for a variety of tasks outside of military use. They are a desirable option for outdoor enthusiasts, hikers, and people looking for tough, dependable footwear since they offer grip, ankle support, and protection.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo1-2">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-2" aria-expanded="false" aria-controls="collapseTwo1-2">
                          What are tactical pouches used for?
                          </button>
                        </h2>
                        <div id="collapseTwo1-2" className="accordion-collapse collapse" aria-labelledby="headingTwo-2" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                            Tactical pouches are used to safely store items including communication devices, tools, magazines, and medical supplies. Quick retrieval of essentials in unpredictable situations is ensured by tactical pouches, which improve accessibility and readiness.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo1-3">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-3" aria-expanded="false" aria-controls="collapseTwo1-3">
                          Why use a gun holster?
                          </button>
                        </h2>
                        <div id="collapseTwo1-3" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                            A gun holster is used for the secure and convenient carrying of firearms. It provides retention, making sure the gun stays in a fixed position and minimises the possibility of unintentional fires. Holsters improve handgun readiness and safety during deployment by shielding the trigger guard and allowing quick, controlled access.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo1-4">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-3" aria-expanded="false" aria-controls="collapseTwo1-4">
                          Is a gun safe in a holster?
                          </button>
                        </h2>
                        <div id="collapseTwo1-4" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                            Yes, a gun is secure within a properly designed holster. A well-fitted holster offers retention mechanisms that keep the gun firmly in place and prevent accidental movement. 
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo1-5">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-5" aria-expanded="false" aria-controls="collapseTwo1-3">
                          What are tactical backpacks used for?
                          </button>
                        </h2>
                        <div id="collapseTwo1-5" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                            As specialised gear carriers, tactical backpacks are used to meet a range of operational requirements. They are used to transport necessary tools and supplies while maximising accessibility and organisation. They improve efficiency and readiness for a variety of missions and activities with features including MOLLE webbing, hydration compatibility, and quick access compartments.
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  {/* /col-lg-6 */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

  )
}

export default TacticalGears
import React from 'react'
import { Link } from 'react-router-dom'
function KnowProduct() {
  return (
    <>
   
            {/* know product */}
            <section className="know-product" style={{ backgroundImage: 'url("/images/know-product.png")' }}>
                <div className="container">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <div className="know-product-text">
                            <h2>Want to know about this product?</h2>
                            <ul>
                                <li>Indian Armour is always happy to hear you – email  <Link to="mailto:info@indianarmour.com">info@indianarmour.com</Link></li>
                                <li>Our Support Section may have the answer you are looking for</li>
                                <li>Alternatively, use our form and we will reply to your enquiry within 24 hours</li>
                            </ul>
                            <div className="input-group">
                                <Link to="/contact-us" className="btn know-more-btn">Enquire Now
                                    <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" />
                </div>
            </section>
            {/* know product */}
    </>
  )
}

export default KnowProduct
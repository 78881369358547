import React from 'react';
import { useState, useEffect } from 'react';
import { useParams,Link,useLocation } from 'react-router-dom';
import { API_URL } from "../utils/utility";
import axios from 'axios'
import { Helmet } from 'react-helmet';

function ProductList() {
  const location = useLocation(); 
  const slug = location.pathname.split("/")[2];
  const [post, setPost] = useState([])
  const API = `https://www.indianarmour.com/indianarmourcms/APIs/Product/index.php?category=${slug}`;
  const ImageURL = 'https://www.indianarmour.com/indianarmourcms/';
  const page_url ="https://www.indianarmour.com"

  const page_url1 = "/ProductList/ballistic-vests";
  const page_url2 = "/ProductList/ballistic-plates";
  const page_url3 = "/ProductList/ballistic-helmet";

  const fetchPost = () => {
    fetch(API)
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        setPost(res)
      })
  }
  useEffect(() => {
    fetchPost()
  }, [])



  console.log("check" + location.pathname)


  return (
    <>
    { 
    location.pathname==page_url1 &&  
    <Helmet>     
    <title> </title>
    <meta name="description" content="" />
    <link rel="canonical" href={page_url + location.pathname} />
    </Helmet>    
    }
   
   { 
    location.pathname==page_url2 &&  
    <Helmet>     
    <title> </title>
    <meta name="description" content="" />
    <link rel="canonical" href={page_url + location.pathname} />
    </Helmet>    
    }
    { 
    location.pathname==page_url3 &&  
    <Helmet>     
    <title> </title>
    <meta name="description" content="" />
    <link rel="canonical" href={page_url + location.pathname} />
    </Helmet>    
    }
    <section
      className="inner-banner d-flex align-items-center"
      style={{ backgroundImage: 'url("/images/blog-banner.jpg")' }}
    >
      <div className="inner-banner-content1">
        <div className="container">
          <div className='row'>
            <div className='col-lg-12'><h1 className="text-white fw-bolder text-capitalize">{slug}</h1></div>
          </div>
        </div>
      </div>
    </section>




    <section className="breadcrumb-inner pt-3  pb-5">
      <div className="container">
        <nav>
          <ol className="breadcrumb mb-0 justify-content-start">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
            
              <Link to="/ProductList/ballistic-vests">Ballistic Vests</Link>
            </li>
            
            <li className="breadcrumb-item active" aria-current="page">
            ProductList
            </li>
          </ol>
        </nav>
      </div>
    </section>
   
    <section className="product-list-page mt-40 pb-100">
      <div className="container">
        <div className="row gy-4">
     
        {/* {post[0].id} */}
       {post[0]?.page_name ? post.map((item, i) => (
          
             <>
             
            
             
            <div
            className="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration="900ms"
            key={i}
          >
            <div className="blog-blk-list">
              <div className="card-img">
                <img src={ImageURL+item.category_image} className="img-fluid w-100" />
              </div>
              <div className="blog-blk-content">
                <h4>
                {item.heading}
                </h4>
                <Link to={`/product/${item.page_name}`} className="know-more ">
                  View Product <img src="/images/arrow.svg" />
                </Link>
              </div>
            </div>
          </div>
            
          
            </>
          
          
          ))
        
        : <div>No Product Found !!!</div>
        }

          
          {/* col-lg-4 */}



         
        </div>
      </div>
    </section>


    
  </>
  )
}

export default ProductList
import React,{useState } from 'react'
import { Link,useLocation } from 'react-router-dom'
import { useDocument } from 'react-firebase-hooks/firestore';


import { Helmet } from 'react-helmet';
import axios,{ AxiosResponse, AxiosError } from 'axios'
function PageNotFound() {

  const document = useDocument();

  document.statusCode = 404;
  // const { setStatus } = useDocument();

  // setStatus(404);
  const location = useLocation();
  const slug1 = location.pathname.split("/")[1];
  // axios.get('foo.example')
  // .then((response) => {
      
  //   }
  //   )
  // .catch ((error )=> {
  //     let errorResponse  ;

  //     // it is an AxiosError
  //     if (error?.isAxiosError) {
  //         const axiosError = error  
  //         errorResponse = axiosError.response; //all the info here
  //     } else {
  //         errorResponse = error; // it is not an AxiosError
  //     }

  //     console.log(errorResponse);
  //     throw errorResponse;
  // })

  // axios.get(slug1)
  // .then((response: AxiosResponse) => {
  //   // Handle response
  // })
  // .catch((reason: AxiosError) => {
  //   if (reason.response?.status === 400) {
  //     // Handle 400
  //   } else {
  //     // Handle else
  //   }
  //   console.log(reason.message)
  // })

  
  // try {
  //   const { data } =   axios.get(`${slug1}`  );
  //   console.log(data[0])
    
  // } catch (error) {
  //   const message =
  //     error.response && error.response.data.message
  //       ? error.response.data.message
  //       : error.message;
     
  // }

 
  return (
    <div>
       <Helmet>
       <title>Page not found</title>
      <meta name='errorpage' content='true' />
      <meta name='errortype' content='404 - Not Found' />
      <meta name='prerender-status-code' content='404' />

    </Helmet>
      


    <section className="breadcrumb-inner pt-3  pb-5">
      <div className="container">
        <nav >
          <ol className="breadcrumb mb-0 justify-content-start">
            <li className="breadcrumb-item"><a href="index.php">Home</a></li>
            <li className="breadcrumb-item active" aria-current="page">404</li>
          </ol>
        </nav>
      </div>
    </section>
    {/* 404 */}
    <section className="error404">
      <div className="container">
        <div className="row">
          <div className="error404-contant">
            <img src="/images/404.svg" />
            <h2>404 Error!</h2>
            
            <Link to="/"  className="back"><i className="fa-solid fa-arrow-left-long" /> Back </Link>
          <input type="hidden" name="notfound" id="notfound" value="404"/>
          </div>
        </div>
      </div>
    </section>
    {/* 404 */}
  </div>
  )
}

export default PageNotFound
import React from 'react'

import ProductDetail from "../../component/ProductDetail";
import ProductFAQ from '../../component/ProductFAQ';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function BallisticProtection() {

  const location = useLocation();

  const slug = location.pathname.split("/")[2];

  return (

    <div>
      <Helmet>
        <title>Ballistic Protection Products | Ballistic Vest, Helmet, and Plate </title>
        <meta name="description" content="With over two decades of experience, Indian Armour develops and manufactures high-quality ballistic protection products such as Ballistic Vest, Ballistic Helmet, and Hard Armour Plates." />
        <link rel="canonical" href="https://www.indianarmour.com/ballistic-protection" />
      </Helmet>
      <section className="inner-banner2 " style={{ backgroundImage: 'url("/images/bg-2.png")' }}>
        <div className="inner-banner2-content d-flex align-items-center">
          <div className="container">
            <h1 className=" fw-bolder">Ballistic Protection</h1>
          </div>
        </div>
      </section>
      <section className="breadcrumb-inner pt-3  pb-5">
        <div className="container">
          <nav >
            <ol className="breadcrumb mb-0 justify-content-start">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Ballistic Protection</li>
            </ol>
          </nav>
        </div>
      </section>
      {/* Ultimate Ballistic Protection */}
      <section className="ballistic-protection">
        <div className="container">
          <div className="row">
            <div className="protection-he">
              <h2>Ultimate Ballistic Protection</h2>
              <p>Indian Armour has experience of more than two decades in developing and manufacturing high-quality <b>body armour protection</b> systems.
                All our <strong>ballistic protection</strong> products are certified by accredited laboratories according to international testing standards.  Our ballistic body armour includes custom-made <Link to="/ballistic-vests" > protective vests</Link><Link to="/ballistic-vests" > plate carrier</Link>   systems, covert armour, overt armour, stab-proof vests, de-mining protection, hard armour panels,
                <Link to="/ballistic-helmet" >ballistic helmets</Link> , and tactical systems. These products are widely popular among security specialists, law enforcement personnel, and Special Forces.</p>
            </div>
          </div>
          <div className="protection-boess">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="protection-boess1" data-aos="fade-right" data-aos-offset={100}>
                  <div className="protection-boess1-right">
                    <h2>Ballistic <br />Vest</h2>
                    <Link to="/ballistic-vests" className="know-more protection-btn">Read More <img src="/images/arrow.svg" /></Link>
                  </div>
                  <div className="protection-boess1-left" data-aos="fade-right" data-aos-offset={100}>
                    <img src="/images/quick-releasevest-slider.png" />
                  </div>
                </div>
                <div className="protection-boess1" data-aos="fade-right" data-aos-offset={100}>
                  <div className="protection-boess1-right">
                    <h2>Ballistic<br />Helmet</h2>
                    <Link to="/ballistic-helmet" className="know-more protection-btn">Read More <img src="/images/arrow.svg" /></Link>
                  </div>
                  <div className="protection-boess1-left" data-aos="fade-right" data-aos-offset={100}>
                    <img src="/images/ballistic-helmet.png" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="protection-boess1" data-aos="fade-left" data-aos-offset={100}>
                  <div className="protection-boess1-right">
                    <h2>Ballistic Plate</h2>
                    <Link to="/ballistic-plates" className="know-more protection-btn">Read More <img src="/images/arrow.svg" /></Link>
                  </div>
                  <div className="protection-boess1-left" data-aos="fade-right" data-aos-offset={100}>
                    <img src="/images/ballistic-plate.png" />
                  </div>
                </div>
                <div className="protection-boess1" data-aos="fade-left" data-aos-offset={100}>
                  <div className="protection-boess1-right">
                    <h2>De-Mining</h2>
                    <Link to="/demining-clothing" className="know-more protection-btn">Read More <img src="/images/arrow.svg" /></Link>
                  </div>
                  <div className="protection-boess1-left" data-aos="fade-right" data-aos-offset={100}>
                    <img src="/images/de-mining.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Ultimate Ballistic Protection */}
      {/* Ballistic Body Armour */}
      <section className="ballistic-armour">
        <div className="container">
          <div className="protection-he">
            <h2>Ballistic Body Armour</h2>
            <p>We offer a wide array of premium ballistic body armour products that offer superior protection against the risks arising from political violence, organized crime, ambush attacks, tactical missions, etc. We have multiple configurations of ballistic plates of various constructions, protection levels, and weights which can be customized according to user requirements.</p>
          </div>
          {/* ballistic-armour-box */}
          <div className="ballistic-armour-box">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <div className="ballistic-armour-box-img" data-aos="fade-right" data-aos-offset={100}>
                  <img src="/images/ballistic-img-1.png" alt='ballistic helmets'/>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div className="ballistic-armour-box-contant" data-aos="fade-left" data-aos-offset={100}>
                  <div className="ballistic-armour-box-text">
                    <h2>Armour Plates &amp; Ballistic Helmets</h2>
                    <p>Our hard body armor and soft body armor provide protection levels of
                      &nbsp;<a href="https://nij.ojp.gov/topics/articles/body-armor-performance-standards">NIJ Level</a>
                      &nbsp;
                      <b>IIIA, III, III+, and IV</b>. Furthermore, we also manufacture ballistic helmets that can be equipped with VAS Shroud and side rails for attaching accessories, as well as the modern FAST inner helmet inclusive straps. Our range of head protection gear is well suited to be used in multiple scenarios which include active shooter situations, disaster relief, high-risk areas such as post-conflict nations, armed security personnel, and military deployments.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ballistic-armour-box */}
          {/* ballistic-armour-box */}
          <div className="ballistic-armour-box">
            <div className="row reverse">
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div className="ballistic-armour-box-contant" data-aos="fade-right" data-aos-offset={100}>
                  <div className="ballistic-armour-box-text2">
                    <h2>Armour Vests</h2>
                    <p>Our vests are engineered with three primary objectives: <b>low weight, superior quality, and maximum safety</b>. With our innovative <b>ballistic vests</b> and protective solutions, we offer enhanced protection to our users. Our vests are designed to offer maximum comfort and freedom of movement which are essential for extended usage without fatigue. </p>
                    <p>Indian Armour’s range of ballistic body armour is the most trusted, lightweight, and offers ergonomic solutions for safety with the highest standards of reliability and durability. Whether you need helmets, plates, vests, or any ballistic protection, we ensure that our product range meets all your armour needs.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <div className="ballistic-armour-box-img2" data-aos="fade-left" data-aos-offset={100}>
                  <img src="/images/ballistic-img-2.png" alt='armour vests'/>
                </div>
              </div>
            </div>
          </div>
          {/* ballistic-armour-box */}
        </div>
      </section>
      {/* Ballistic Body Armour */}
      {/* Products for Defense Personnel */}
      <section className="defense-personnel">
        <div className="container">
          <div className="protection-he">
            <h2>Products for Defense Personnel</h2>

          </div>
          <div className="defense-personnel-tab">
            <div className="row g-0" data-aos="fade-up" data-aos-duration={1500}>
              <div className="col-lg-4 col-sm-4">
                <ul className="nav nav-pills flex-column" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a className="nav-link active" data-bs-toggle="pill" href="#menu1" aria-selected="true" role="tab">
                      <div className="personnel-icon">
                        <span>Ballistic Shield</span>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu2" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>Ballistic Technical Fabric</span>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu3" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>Armoured Vehicle</span>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu4" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>Bomb Blanket</span>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu5" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>Composite Ballistic Panel</span>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu6" aria-selected="false" tabIndex={-1} role="tab">
                      <div className="personnel-icon">
                        <span>Spall Liner</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-8  col-sm-8">
                <div className="tab-content">
                  <div className="tab-pane container  active" id="menu1" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                            <img src="/images/ballistic_shield.png" className="img-fluid" alt="ballistic shield" />
                            <div className="personnel-text">
                              <h2>Ballistic Shield</h2>
                              <p>The Indian Armour range of ballistic shields incorporates the latest materials technology and a handle system that has been ergonomically developed over a number of years.</p>
                              <a href="product/ballistic-shield" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="menu2" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center ">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                            <img src="/images/ballistic_technical_fabric.png" className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Ballistic Technical Fabric</h2>
                              <p>We at Indian Armour believe in the importance of working with customers to deliver solutions that help their business prosper and grow.</p>
                              <a href="product/ballistic-technical-fabric" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="menu3" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                            <img src="/images/armoured_vehicle.png" className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Armoured Vehicle</h2>
                              <p>Indian Armour is a high-quality specialized manufacturer of armoured vehicles.</p>
                               
                            {/* <a href="product/platform-protection" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></a> */}
                               </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="menu4" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                            <img src="/images/bomb_blanket.png" className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Bomb Blanket</h2>
                              <p>A lightweight & portable ballistic & fragmentation-resistant blanket, which can be configured in any shape & size, & can be easily folded over or placed on top of any ballistic threat.</p>
                              <a href="product/bomb-banket" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="menu5" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                            <img src="/images/composite_ballistic_panel.png" className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Composite Ballistic Panels</h2>
                              <p>Hard Shell bullet-resistant panels are manufactured using multiple layers of High-Performance Polyethylene (HPPE) and using European or American raw materials (Honeywell / Teijin / DuPont).</p>
                              <a href="product/composite-ballistic-panels" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="menu6" role="tabpanel">
                    <div className="personnel-content">
                      <div className="row align-items-center ">
                        <div className="col-lg-12">
                          <div className="personnel-img">
                            <img src="/images/spall_liner.png" className="img-fluid" alt="" />
                            <div className="personnel-text">
                              <h2>Spall liners</h2>
                              <p>We at Indian Armour believe in the importance of working with customers to deliver solutions that help their business prosper and grow.</p>
                              <a href="/product/spall-liner" className="know-more personnel-btn">View Product <img src="/images/arrow-w.svg" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Products for Defense Personnel */}
      {/* faq */}
      <section className="faq-sec">
        <div className="container">
          <div className="faq-he">
            <h2>Frequently asked questions</h2>
          </div>
          <div className="row">
            <div className="faq-accordion">
              <div className="row">
                <div className="col-lg-6" data-aos="fade-right" data-aos-offset={300}>
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        What are the highest level of ballistic helmets?
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">
                          <p>Ballistic helmets are generally tested to meet NIJ standards. Typically the highest rated helmets provide protection as per NIJ Level IIIA.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        What is a ballistic helmet used for?
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>
                          A ballistic helmet's function is to protect the wearer's head against bullets, shrapnel, and other threats encountered in combat or in other high-risk situations. It helps to lower the risk of head injuries among military personnel, law enforcement, and security forces.

                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading3">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseTwo">
                        Is a ballistic helmet bulletproof?
                        </button>
                      </h2>
                      <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>
                          Ballistic helmets are not completely bulletproof, but they reduce the impact of certain types of bullets, shrapnel, and other impacts by absorbing and dispersing the impact energy. However, it is to be noted that it may not stop high-powered rifle bullets.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col-lg-6 */}
                <div className="col-lg-6" data-aos="fade-left" data-aos-offset={300}>
                  <div className="accordion" id="accordionExample1">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne1-1">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1-1" aria-expanded="true" aria-controls="collapseOne1-1">
                        Do Kevlar helmets expire?
                        </button>
                      </h2>
                      <div id="collapseOne1-1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                          Yes, Kevlar helmets do expire. They gradually lose the ability to provide ballistic protection over time. The material may degrade with time due to external factors such as heat, UV rays, humidity, etc.

                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo1-2">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-2" aria-expanded="false" aria-controls="collapseTwo1-2">
                        What is the difference between Level III and Level IV body armour?

                        </button>
                      </h2>
                      <div id="collapseTwo1-2" className="accordion-collapse collapse" aria-labelledby="headingTwo-2" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                          Level IV body armour provides a higher level of protection against bullets when compared to Level III body armour. Owing to its better protective abilities, Level IV body armour is also generally heavier and thicker than Level III body armour.

                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo1-3">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-3" aria-expanded="false" aria-controls="collapseTwo1-3">
                          What is the difference between Level 3 and Level 4 body armour?
                        </button>
                      </h2>
                      <div id="collapseTwo1-3" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* /col-lg-6 */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

export default BallisticProtection
import React from 'react'
import { Link } from 'react-router-dom';
function AntiRiotGear() {
  return (
    <>
      <section className="inner-banner2 " style={{backgroundImage: 'url("/images/anti-riot-gear.png")'}}>
          <div className="inner-banner2-content d-flex align-items-center">
            <div className="container">
              <h1 className=" fw-bolder">Anti-Riot Gear</h1>
              <strong>Gear for Guardians</strong>
            </div>
          </div>
        </section>
        <section className="breadcrumb-inner pt-3  pb-5">
          <div className="container">
            <nav >
              <ol className="breadcrumb mb-0 justify-content-start">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Anti Riot Gear</li>
              </ol>
            </nav>
          </div>
        </section>


        <section className="py-70">
            <div className="container">
                <div className="row">                  
                    <p>While upholding law and order and protecting the public, law enforcement and security officers might encounter various threats. Keeping mobs under control during riots, protests, or any other type of public disruption is an essential part of their job. To perform their task efficiently and safely, these professionals need anti-riot gear of the highest caliber. This is where Indian Armour comes to the rescue. </p>
                    <p>One of the leading manufacturers of personal protection gear, Indian Armour has a wide range of anti-riot gear that is designed to provide optimal protection and performance. Here you will find everything in one place!</p>
                </div>
            </div>
        </section>

        <section className=" bg-pink py-70">
              <div className='container'>
              <div className="row align-items-center gx-5">
                <div className="col-lg-6" >
                  <div className="ballistic-armour-box-img" data-aos="fade-right" data-aos-offset={100}>
                    <img src="/images/anti-riot-gear1.png" className='img-fluid' alt='Customize Your Ballistic Protection Gear' />
                  </div>
                </div>
                <div className="col-lg-6" data-aos="fade-left" data-aos-offset={100}>
                
                <div className="bg-custom bpcbo-content">
                      <h2><strong>Riot Shields:</strong></h2>
                      <h3>The First Line of Defence</h3>
                      <p>Riot shields are an essential gear for any law enforcement officer involved in crowd control. Indian Armour's riot shields are composed of advanced materials, which guarantees the shield's low weight and remarkable resilience. They provide superior protection against thrown objects, blunt force, and other potential threats. </p>
                      <p>The transparent design of the shield ensures that the officers can shield themselves and still have clear visibility. Additionally, the ergonomic features of our riot shields include soft grips and straps that make them easy to use for extended periods of time without fatigue. </p>
                      <button class="btn know-more-btn submit">View Product<span class="input-group-text"><img src="/images/arrow.svg" width="15" height="10" loading="lazy" /></span></button>
                  </div>
                 
                </div>
              </div>
              </div>
            </section>

            <section className=" py-70">
              <div className='container'>
              <div className="row reverse align-items-center gx-5">
                <div className="col-lg-6" data-aos="fade-right" data-aos-offset={100}>                 
                <div className="bg-custom bpcbo-content">
                <h2><strong>Riot Helmets: </strong></h2>
                      <h3>A Safe Head is a Cool Head</h3>
                      <p>Head protection becomes crucial during riot control. Our riot helmets are made to provide all-around protection. Utilizing materials with exceptional strength, they can resist blows from multiple objects, including stones. Our riot helmets come with padding and straps that can be adjusted to provide a snug and comfortable fit. The ventilation holes ensure that the wearer can stay cool even in high-stress circumstances. </p>
                      <button class="btn know-more-btn submit">View Product<span class="input-group-text"><img src="/images/arrow.svg" width="15" height="10" loading="lazy" /></span></button>      
                 </div>
                </div>
                <div className="col-lg-6">
                  <div className="ballistic-armour-box-img2" data-aos="fade-left" data-aos-offset={100}>
                    <img src="/images/anti-riot-gear2.png" className='img-fluid' alt='Personalize Your Ballistic Vest, Ballistic Plate, and Ballistic Helmet to Your Specifications'/>
                  </div>
                </div>
                </div>
              </div>
            </section>

     

            <section className=" bg-pink  py-70">
              <div className='container'>
              <div className="row  align-items-center gx-5">
              <div className="col-lg-6">
                  <div className="ballistic-armour-box-img2" data-aos="fade-left" data-aos-offset={100}>
                    <img src="/images/anti-riot-gear3.png" className='img-fluid' alt='Personalize Your Ballistic Vest, Ballistic Plate, and Ballistic Helmet to Your Specifications'/>
                  </div>
                </div>
                <div className="col-lg-6" data-aos="fade-right" data-aos-offset={100}>                 
                <div className="bg-custom bpcbo-content">
                     <h2><strong>Riot Suits:  </strong></h2>
                      <h3>Because Full Body Matters </h3>
                      <p>Modern riot suits by Indian Armour provide complete body protection from a range of threats, such as sharp objects, and blunt force injuries. The extensive body coverage doesn't limit mobility, while the suits' lightweight and breathable design allows cops to move freely and stay comfortable during extended wear. The modular design makes it simple to modify and customize them to meet specific needs. </p>
                      <button class="btn know-more-btn submit">View Product<span class="input-group-text"><img src="/images/arrow.svg" width="15" height="10" loading="lazy" /></span></button>                 
                 </div>
                </div>
           
                </div>
              </div>
            </section>

            <section className=" py-70">
              <div className='container'>
              <div className="row reverse  align-items-center gx-5">
                
                <div className="col-lg-6" data-aos="fade-left" data-aos-offset={100}>
                
                <div className="bg-custom bpcbo-content">
                      <h2><strong>Knee and Elbow Pads:  </strong></h2>
                      <h3>Extra Protection for Vulnerable Areas </h3>
                      <p>Knee and elbow pads are indispensable for the purpose of safeguarding delicate joints during physical confrontations. The knee and elbow pads from Indian Armour are composed of durable, impact-absorbing materials that offer superior protection against impacts.  </p>
                      <p>With adjustable straps that keep the pads in place even during strenuous activity, the design guarantees a secure fit. The lightweight and flexible nature of these pads allows a complete range of motion without compromising protection. </p>
                      <button class="btn know-more-btn submit">View Product<span class="input-group-text"><img src="/images/arrow.svg" width="15" height="10" loading="lazy" /></span></button>
                  </div>
                 
                </div>
                <div className="col-lg-6" >
                  <div className="ballistic-armour-box-img" data-aos="fade-right" data-aos-offset={100}>
                    <img src="/images/anti-riot-gear4.png" className='img-fluid' alt='Customize Your Ballistic Protection Gear' />
                  </div>
                </div>
              </div>
              </div>
            </section>

            <section className=" bg-pink  py-70">
              <div className='container'>
              <div className="row  align-items-center gx-5">
              <div className="col-lg-6">
                  <div className="ballistic-armour-box-img5" data-aos="fade-left" data-aos-offset={100}>
                    <img src="/images/anti-riot-gear5.png" className='img-fluid' alt='Personalize Your Ballistic Vest, Ballistic Plate, and Ballistic Helmet to Your Specifications'/>
                  </div>
                </div>
                <div className="col-lg-6" data-aos="fade-right" data-aos-offset={100}>                 
                <div className="bg-custom bpcbo-content">
                     <h2><strong>Gloves:   </strong></h2>
                      <h3>Hold with Strength </h3>
                      <p>Hands are often at risk during riot situations, and protecting them is crucial for maintaining functionality and control. Indian Armour's gloves excel in providing protection with exceptional grip and dexterity.  </p>
                      <p>Made from sturdy materials, these gloves are resistant to cuts, abrasions, and impacts. A firm grip is guaranteed even in damp conditions thanks to the textured palm and strengthened knuckles, which also provide additional protection against blunt force. Our gloves' breathable fabrics and adjustable straps ensure a secure and comfortable fit.</p>
                      <button class="btn know-more-btn submit">View Product<span class="input-group-text"><img src="/images/arrow.svg" width="15" height="10" loading="lazy" /></span></button>                 
                 </div>
                </div>
               
                </div>
              </div>
            </section>

            <section className="py-70">
              <div className='container'>
              <div className="row align-items-center gx-5">
                
                <div className="col-lg-12" data-aos="fade-left" data-aos-offset={100}>
                
                <div className="bg-custom bpcbo-content">
                      <h2 className='text-center mb-3'>The Indian Armour Promise</h2>
                    
                      <p>At Indian Armour, we recognize the vital need for reliable and robust anti-riot gear. Our products offer maximum comfort and protection, enabling law enforcement and security professionals to carry out their tasks effectively and safely. Investing in top-notch equipment at Indian Armour will give you the confidence to manage any situation that arises. So go ahead and browse through our extensive collection of anti-riot gear to equip yourself with high-quality protection.</p>
                    <div className="text-center d-flex justify-content-center">
                    <a href='/contact-us' class="btn know-more-btn submit">Contact Us<span class="input-group-text"><img src="/images/arrow.svg" width="15" height="10" loading="lazy" /></span></a>   
                    </div>
                  </div>
                 
                </div>
              </div>
              </div>
            </section>


            <section className="faq-sec">
          <div className="container">
            <div className="faq-he">
              <h2>Frequently asked questions</h2>
            </div>
            <div className="row">
              <div className="faq-accordion">
                <div className="row">
                  <div className="col-lg-6" data-aos="fade-right" data-aos-offset={300}>
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          What is riot gear used for?
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{}}>
                          <div className="accordion-body">
                            <p>Riot gear is used to protect law enforcement personnel from riots, protests, and other public disturbances by the deployment of riot gear. It minimizes the risk of injuries from thrown objects, blunt force, and other threats.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Are riot shields bulletproof?
                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>
                            Riot shields are not bulletproof. Their purpose is to protect against threats such as blunt force and thrown objects. When it comes to protection against bullets, ballistic shields are the right choice to make.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading3">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseTwo">
                          Can civilians buy riot gear?
                          </button>
                        </h2>
                        <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>
                            Yes, civilians can buy riot gear, depending upon the availability and local laws & regulations in the given region.
                            </p>
                          </div>
                        </div>
                      </div>

                 


                    </div>
                  </div>
                  {/* /col-lg-6 */}
                  <div className="col-lg-6" data-aos="fade-left" data-aos-offset={300}>
                    <div className="accordion" id="accordionExample1">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne1-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1-1" aria-expanded="true" aria-controls="collapseOne1-1">
                          What type of riot gear is used by police?
                          </button>
                        </h2>
                        <div id="collapseOne1-1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                            Police use gear such as riot shields, helmets, suits, knee and elbow pads, and gloves, among others. The riot gear can be customized specific to the situation.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo1-2">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-2" aria-expanded="false" aria-controls="collapseTwo1-2">
                          How heavy is a riot shield?
                          </button>
                        </h2>
                        <div id="collapseTwo1-2" className="accordion-collapse collapse" aria-labelledby="headingTwo-2" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                            The weight of riot shields depends upon the materials they are made of. Indian Armour provides lightweight riot shields, which enhance mobility without compromising protection. 
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo1-3">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-3" aria-expanded="false" aria-controls="collapseTwo1-2">
                          Are riot helmets bulletproof?
                          </button>
                        </h2>
                        <div id="collapseTwo1-3" className="accordion-collapse collapse" aria-labelledby="headingTwo-2" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                            Riot helmets are not bulletproof. They protect against threats such as blunt force and thrown objects. When it comes to protection against bullets, ballistic helmets are used.
                            </p>
                          </div>
                        </div>
                      </div>
                   

                   


                    </div>
                  </div>
                  {/* /col-lg-6 */}
                </div>
              </div>
            </div>
          </div>
        </section>


    </>
  )
}

export default AntiRiotGear
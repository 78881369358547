import React from 'react'
 
import ProductDetail from "../../component/ProductDetail";
import ProductFAQ from '../../component/ProductFAQ';
import { useLocation } from 'react-router-dom';
import Slider from "react-slick";
import { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet"
import { Link } from 'react-router-dom'
import { BASE_URL } from '../../utils/utility';
import Certifcates from '../../component/Certifcates';
import TopSectionProduct from '../../component/TopSectionProduct';

function Harness() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

    
  const location = useLocation();
 
  const slug = location.pathname.split("/")[2];
  
  return ( 
    <div>
      <TopSectionProduct/>
  <div className="verticale-slider">
  

    {/* left right */}
    <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex={0}>
      <section className="vest-left-box section" id="scroll1">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
              <div className="vest-left-img vest-right-img" data-aos="fade-right" data-aos-offset={100}>
              <img src="/images/OCC.png" />
              </div>
            </div>
            <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
              <div className="vest-box-set" data-aos="fade-left" data-aos-offset={100}>
                <div className="vest-left-y-text">
                  <h2>Retention System with Worm Dial</h2>
                  <p>This kit includes essential components like (1) front EPP pad, (1) OccDial Fitband, (1) Head-Loc 4-Point Chinstrap, and 15/22mm hardware
                  (depending on the helmet size). Available in M, L, XL, and XXL sizes, it
                  caters to various helmet sizes, ensuring a perfect fit.
                  For the FAST Bump Sport version, the chinstrap is thoughtfully
                  wrapped in neoprene to provide a comfortable fit under the chin
                  during use.
            
              
                  </p>
                  <div className="input-group mt-5">
                  <Link to="/product/retention-system-with-worm-dial" className="btn know-more-btn submit">View Product
                        <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                      </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* left right */}
      {/* left right */}
      <section className="vest-left-box vest-left-box2 section" id="scroll2">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
              <div className="vest-box-set-left" data-aos="fade-right" data-aos-offset={100}>
                <div className="vest-left-y-text">
                  <h2>Retention System with RSC Dial</h2>
                  <p>These modular and durable pads are designed to have low absorbency
                    and promote breathability.
                    This innovative system is offered with two retention options: the 4-Point
                    Head-Loc H-Nape Chinstrap and the Worm-Dial Fit-Band with 4-Point
                    Head-Loc Chinstrap. The traditional H-Nape is ideal for supporting
                    heavier NVGs during demanding activities, while the Worm-Dial FitBand allows for quick adjustments with its low-profile design, utilizing
                    the padding, fit-band, and chinstrap to secure the helmet.</p>
                  <div className="input-group mt-5">
                  <Link to="/product/retention-system-with-rsc-dial" className="btn know-more-btn submit">View Product
                        <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                      </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
              <div className="vest-left-img" data-aos="fade-left" data-aos-offset={100}>
              <img src="/images/Warm.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
   
          </div>
 
       
          <section className="nij-levels">
          <div className="container">

            {/* Certificates */}
           <Certifcates></Certifcates>
            {/* Certificates */}
          </div>
        </section>
      {/* What are the NIJ Levels for HarnessHarness? */}
      {/* contact form */}
      {/* <section class="contact-us-sec" style="background-image:url('images/contact-bg.png');">
<div class="container">
  <div class="row">
     <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div class="contact-detail" data-aos="fade-right" data-aos-offset="300">
         <div class="">
           <div class="contact-1">
          <ul>
            <li class="contact-1-img"><img src="/images/icon-1.png"></li>
            <li>Call Us on<br> <a href="tel:9811268600">+91-9811268600</a> </li>
         </ul>
         </div>

         <div class="contact-1">
             <ul>
                 <li class="contact-1-img"><img src="/images/icon-2.png"></li>
                 <li>Email<br> <a href="mailto:info@indianarmour.com">info@indianarmour.com</a> </li>
             </ul>
         </div>
         </div>
        </div>
     </div>
     <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div class="conact-form-ballistic" data-aos="fade-left" data-aos-offset="300">
            <h2>Contact US</h2>
            <form class="ballistic-form">
                
                <div class="mb-3">

<input type="email" class="form-control"  placeholder="Name">
</div>

<div class="mb-3">

<input type="email" class="form-control"  placeholder="Email">
</div>

<div class="mb-3">

<input type="text" class="form-control"  placeholder="Location">
</div>

<div class="mb-3">
<textarea class="form-control" rows="5" placeholder="Message"></textarea>
</div>

<div class="mb-3">

<div class="input-group mt-5">
            <a href="#" class="btn know-more-btn submit">Submit
              <span class="input-group-text"><img src="/images/arrow.svg"></span>
            </a>
          </div>


</div>

            </form>
        </div>
     </div>
  </div>
</div>
</section> */}
      {/* contact form */}
      {/* faq */}
      <section className="faq-sec">
        <div className="container">
          <div className="faq-he"><h2>Frequently asked questions</h2></div>
          <div className="row">
            <div className="faq-accordion">
              <div className="row">
                <div className="col-lg-6" >
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          Are there any Level 4 ballistic helmets?
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">
                          <p>No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          What is a ballistic helmet used for?
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading3">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseTwo">
                          Is the helmet bulletproof?
                        </button>
                      </h2>
                      <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{/* /col-lg-6 */}
                <div className="col-lg-6">
                  <div className="accordion" id="accordionExample1">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne1-1">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1-1" aria-expanded="true" aria-controls="collapseOne1-1">
                          Can a ballistic helmet stop a bullet?
                        </button>
                      </h2>
                      <div id="collapseOne1-1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo1-2">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-2" aria-expanded="false" aria-controls="collapseTwo1-2">
                          Do Kevlar helmets expire?
                        </button>
                      </h2>
                      <div id="collapseTwo1-2" className="accordion-collapse collapse" aria-labelledby="headingTwo-2" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo1-3">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-3" aria-expanded="false" aria-controls="collapseTwo1-3">
                          What is the difference between Level 3 and Level 4 body armour? 
                        </button>
                      </h2>
                      <div id="collapseTwo1-3" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{/* /col-lg-6 */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    
    

    
    </div>
  )
}

export default Harness
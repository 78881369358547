import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function Faq() {
  return (
    <div>
       <Helmet>
        <title>Answers to Your Questions | Indian Armour FAQs   </title>
        <meta name="description" content="Find solutions and clarity in our Frequently Asked Questions (FAQs) section. Explore comprehensive answers to common queries about Indian Armour products, services, and policies." />
        <link rel="canonical" href="https://www.indianarmour.com/faq" />
      </Helmet>
        <section className="inner-banner d-flex align-items-center justify-content-center text-center " style={{backgroundImage: 'url("/images/faq.jpg")'}}>
          <div className="inner-banner-content1">
            <div className="container">
              <h1 className="text-white fw-bolder">Frequently Asked Questions</h1>
            </div>
          </div>
        </section>
        <section className="breadcrumb-inner pt-3  pb-5">
          <div className="container">
            <nav >
              <ol className="breadcrumb mb-0 justify-content-start">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Frequently Asked Questions</li>
              </ol>
            </nav>
          </div>
        </section>
        <section className="faq py-5 right-black-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ul className="nav nav-pills " id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Order Status</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Product Questions</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Testing &amp; Technical Details</button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                What is the status of my order?
                              </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p>Please contact us by email at <Link to="mailto:info@indianarmour.com">info@indianarmour.com</Link> or by phone at <Link to="tel:+91-9811268600">+91-9811268600</Link> if you have any queries concerning the progress of an order or any other product-related questions.</p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              How will my goods be delivered?
                              </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p>
                                Body armour packages vary in weight, and all orders are shipped via FedEx and DHL, with order tracking information accessible. Shipping details will be emailed to you as soon as your order leaves our facility. Actual transit time of shipping are subject to quantity and location.</p>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingthree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree" aria-expanded="false" aria-controls="collapsethree">
                              What is product lead time?
                              </button>
                            </h2>
                            <div id="collapsethree" className="accordion-collapse collapse" aria-labelledby="headingthree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p>It generally takes 2-3 business weeks. The majority of our goods are custom-made to order and must pass stringent quality assurance tests before they leave our facility. We take pleasure in providing the finest quality items to our clients and appreciate your time.</p>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingthree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsethree">
                              Does Indian Armour systems ship internationally?
                              </button>
                            </h2>
                            <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingthree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p>Yes, we ship to international locations. However, there are documentation that is required in order to export, which we can advise on once we know the intended final destination. You may also check with your own government to find out about body armour import restrictions in your nation, as well as anticipated import duties and taxes.</p>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingthree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsethree">
                              Are the product testimonials on the Indian Armour System’s website from real customers?
                              </button>
                            </h2>
                            <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingthree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p>Yes! We have received 100 percent of our reviews from genuine customers who have purchased from us and opted to leave a review in their own words. We do not alter reviews without the author’s consent.</p>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    
                    </div>
                  </div>



                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div className="row">
                      <div className="col-lg-12">
                        <div className="accordion" id="accordionExample1">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne">
                              What is the difference between inserts, panels, and plates?
                              </button>
                            </h2>
                            <div id="collapseOne1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                              <div className="accordion-body">
                                <p> Panel, plate, and insert are all synonyms for an armour plate or panel that is inserted into a bag pack, or carrier.</p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo">
                              What is a soft body armour?
                              </button>
                            </h2>
                            <div id="collapseTwo2" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                              <div className="accordion-body">
                                <p>
                                Soft body armour is comprised of soft, flexible materials that can bend in different directions. Indian Armour Systems provides soft body armour against small arms at NIJ levels IIA, II, and IIIA within bullet proof vests. If you want your bulletproof vest to block bullets that are more powerful than a.44 Magnum, you need to insert Ballistic hard armour plates inside the carrier.</p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingthree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree3" aria-expanded="false" aria-controls="collapsethree">
                              What are Ballistic plates (Hard Armour Plate)?
                              </button>
                            </h2>
                            <div id="collapsethree3" className="accordion-collapse collapse" aria-labelledby="headingthree" data-bs-parent="#accordionExample1">
                              <div className="accordion-body">
                                <p>Hard armour, also known as ballistic plates, are hard plates composed of compressed polyethylene or ceramic composite that are intended to block bullets that are more powerful than a.44 Magnum.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    </div>

                  </div>


                  <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                      <div className="row">
                      <div className="col-lg-12">
                        <div className="accordion" id="accordionExample2">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1-1" aria-expanded="true" aria-controls="collapseOne">
                              Are your products tested?
                              </button>
                            </h2>
                            <div id="collapseOne1-1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample2">
                              <div className="accordion-body">
                                <p> Yes, our products have been tested at international laboratories such as H.P. White’s Laboratory, Whitshire, and NIJ, and test records are available upon request. In addition, our company is also listed on the NIJ website. For further information, please visit <Link to="https://www.justnet.org/compliant/ballistic cpl.html.">https://www.justnet.org/compliant/ballistic cpl.html.</Link></p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2-2" aria-expanded="false" aria-controls="collapseTwo">
                              What is NIJ?
                              </button>
                            </h2>
                            <div id="collapseTwo2-2" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample2">
                              <div className="accordion-body">
                                <p>
                                There are various ballistics protection level standards. The US National Institute of Justice (NIJ) is the most widely accepted standard for body armour. This is widely regarded as the industry standard for testing body armour. The NIJ guidelines specify the threats that each level of body armour will shield against, as well as the power of the assault that it will withstand.</p>
                              </div>
                            </div>
                          </div>
                   
                        </div>
                      </div>
                    
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  )
}

export default Faq
import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
function ThankYou() {
  return (
    <div>
         <Helmet>
        <title>Thankyou</title>
        <meta name="description" content="Thankyou" />
        <link rel="canonical" href="https://www.indianarmour.com/thankyou" />
      </Helmet>
    <section className="breadcrumb-inner pt-3  pb-5">
      <div className="container">
        <nav >
          <ol className="breadcrumb mb-0 justify-content-start">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Thankyou</li>
          </ol>
        </nav>
      </div>
    </section>
    {/* 404 */}
    <section className="error404">
      <div className="container">
        <div className="row">
          <div className="error404-contant">
            <img src="/images/Thank_You.svg" />
            <h2>Thank You!</h2>
            <h3>We have received your details.</h3>
          
            <Link to="/"  className="back"><i className="fa-solid fa-arrow-left-long" /> Back </Link>
          </div>
        </div>
      </div>
    </section>
    {/* 404 */}
  </div>
  )
}

export default ThankYou
import './App.css';
import React from 'react';
import Header from './component/Header';
import MainFooter from './component/MainFooter';
import Home from './pages/Home';
import $ from "jquery";
import { Routes, Route } from "react-router-dom";
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import AboutUs from './pages/AboutUs';
import Faq from './pages/Faq';
import BlogDetail from './pages/BlogDetail';
import Certification from './pages/Certification';
import Bespoke from './pages/product/Bespoke';
import BallisticProtection from './pages/product/BallisticProtection';
import BallisticHelmet from './pages/product/BallisticHelmet';
import BallisticVests from './pages/product/BallisticVests';
import { Helmet } from "react-helmet"
import Armedvehicle from './pages/product/Armedvehicle';
import FrontOpenMolleWebbingVest from './pages/product/FrontOpenMolleWebbingVest';
import Overtcarrier from './pages/product/Overtcarrier';
import Gauzyvest from './pages/product/Gauzyvest';
import ThankYou from './pages/ThankYou';
import PageNotFound from './pages/PageNotFound';
import ScrollToTop from './component/ScrollToTop';
import Armyvest from './pages/product/Armyvest';
import AssaultVest from './pages/product/AssaultVest';
import FlotationVest from './pages/product/FlotationVest';
import Gpv from './pages/product/Gpv';
import QuickReleaseVest from './pages/product/QuickReleaseVest';
import RescuerVest from './pages/product/RescuerVest';
import SaviorVest from './pages/product/SaviorVest';
import Torc from './pages/product/Torc';
import K9 from './pages/product/K9';
import ValiantTacticalCarrier from './pages/product/ValiantTacticalCarrier';
import VipVest from './pages/product/VipVest';
import PlateCarrierVestSlickI from './pages/product/PlateCarrierVestSlickI';
import MichWithAcc from './pages/product/MichWithAcc';
import PasgtWithAcc from './pages/product/PasgtWithAcc';
import SfWithAcc from './pages/product/SfWithAcc';
import MidCutWithAcc from './pages/product/MidCutWithAcc';
import BallisticGoggles from './pages/product/BallisticGoggles';
import Patka from './pages/product/Patka';
import DeminingApron from './pages/product/DeminingApron';
import DeminingVest from './pages/product/DeminingVest';
import DeminingSuit from './pages/product/DeminingSuit';
import LandProtection from './pages/product/LandProtection';
import NavalProtection from './pages/product/NavalProtection';
import AirProtection from './pages/product/AirProtection';
import StepInVisor from './pages/product/StepInVisor';
import BallisticVisior from './pages/product/BallisticVisior';
import HoleSkeleton from './pages/product/HoleSkeleton';
import EppPad from './pages/product/EppPad';
import LinerPad from './pages/product/LinerPad';
import RetentionSystemWithWormDial from './pages/product/RetentionSystemWithWormDial';
import RetentionSystemwithRscDial from './pages/product/RetentionSystemwithRscDial';
import BallisticPlates from './pages/product/BallisticPlates';
import DeminingClothing from './pages/product/DeminingClothing';
import PlatformProtection from './pages/product/PlatformProtection';
import BallisticFoldingShield from './pages/product/BallisticFoldingShield';
import CompositeBallisticPanels from './pages/product/CompositeBallisticPanels';
import BombBlanket from './pages/product/BombBlanket';
import BallisticTechnicalFabric from './pages/product/BallisticTechnicalFabric';
import BallisticShield from './pages/product/BallisticShield';
import SpallLiner from './pages/product/SpallLiner';
import FaceGuardVisor from './pages/product/FaceGuardVisor';
import NvgShroud from './pages/product/NvgShroud';
import Pads from './pages/product/Pads';
import SideRails from './pages/product/SideRails';
import TacticalBagForWeapons from './pages/product/TacticalBagForWeapons';
import CounterWeight from './pages/product/CounterWeight';
import TacticalTrousers from './pages/product/TacticalTrousers';
import TacticalUniform from './pages/product/TacticalUniform';
import FirstAidKit from './pages/product/FirstAidKit';
import VehicleMedicalBag from './pages/product/VehicleMedicalBag';

import TacticalBelt from './pages/product/TacticalBelt';
import MilitaryShoes from './pages/product/MilitaryShoes';
import Harness from './pages/product/Harness';
import Level3Rectangular145Kg from './pages/product/Level3Rectangular145Kg';
import Level3CornerCut135Kg from './pages/product/Level3CornerCut135Kg';
import Level3ShooterSwimmerCut125Kg from './pages/product/Level3ShooterSwimmerCut125Kg';
import LevelLvCornerCutSingleCurve270Kg from './pages/product/LevelLvCornerCutSingleCurve270Kg';
import LevelLvShooterSwimmerCut250Kg from './pages/product/LevelLvShooter&SwimmerCut250Kg';
import Product from './pages/Product';
import BallisticHelmetAcc from './pages/product/BallisticHelmetAcc';
import TacticalGears from './pages/product/TacticalGears';
import BespokeSolutions from './pages/BespokeSolutions';
import PrivacyPolicy from './pages/PrivacyPolicy';
// import Slider from './pages/slider';
import ProductList from './pages/ProductList';
import HardArmourPanelLeveliii from './pages/product/HardArmourPanelLeveliii';
import LetsKnowWhatBodyArmourLs from './pages/LetsKnowWhatBodyArmourLs';
import TacticalBulletProofPlateCarrierVest from './pages/TacticalBulletProofPlateCarrierVest';
import HardArmourPanelLeveliii29kg from './pages/product/HardArmourPanelLeveliii29kg';
import HardArmourPanelLeveliii18Kg from './pages/product/HardArmourPanelLeveliii18Kg';
import HardArmourPanelLeveliii32Kg from './pages/product/HardArmourPanelLeveliii32Kg';
import HardArmourPanelLeveliv26Kg from './pages/product/HardArmourPanelLeveliv26Kg';
import HardArmourPanelLeveliv32Kg from './pages/product/HardArmourPanelLeveliv32Kg';
import RapsHelmet from './pages/product/RapsHelmet';
import BallisticProductsCustomizationBulkOrdering from './pages/product/BallisticProductsCustomizationBulkOrdering';
import AntiRiotGear from './pages/AntiRiotGear';


function App() {
  useEffect(() => {
    AOS.init();

    
var header = $('header');
var shrinkIt = header.height();

$(window).scroll(function() {
  var scroll = $(window).scrollTop();

  if ( scroll >= shrinkIt ) {
    header.addClass('fixed-header');
  }
  else {
    header.removeClass('fixed-header');
  }
});

$('header li').click(function(){
    $(this).addClass('active').siblings().removeClass('active');
});
$('.navbar-brand').click(function(){
  $('header li').removeClass('active');
})
$(function() {
  
    var link = $('#navbar1 a.dot1');
    
    // Move to specific section when click on menu link
    link.on('click', function(e) {
      var target = $($(this).attr('href'));
      $('html, body').animate({
        scrollTop: target.offset().top
      }, 600);
      $(this).addClass('active1');
      e.preventDefault();
    });
    
    // Run the scrNav when scroll
    $(window).on('scroll', function(){
      scrNav();
    });
    
    // scrNav function 
    // Change active dot according to the active section in the window
    function scrNav() {
      var sTop = $(window).scrollTop();
      $('section').each(function() {
        var id = $(this).attr('id'),
            offset = $(this).offset().top-1,
            height = $(this).height();
        if(sTop >= offset && sTop < (offset + height)) {
          link.removeClass('active1');
          $('#navbar1').find('[data-scroll="' + id + '"]').addClass('active1');
        }
      });
    }
    scrNav();
  });

  var s = $(".navbar1");
  var pos = s.position();            
  $(window).scroll(function() {
    var windowpos = $(window).scrollTop();
    if (windowpos >= 700 & windowpos <=3500) {
      s.addClass("stick");
    } else {
      s.removeClass("stick"); 
    }
  });


  }, [])
  return (
    <>
      <ScrollToTop />
      
      <Header />

      <Routes>
        <Route exact path='/' element={<Home />} />

        <Route exact path='/ProductList/:id' element={<ProductList />} />
        {/* <Route exact path='/Slider' element={<Slider />} /> */}
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/:slug" element={<BlogDetail />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/certification" element={<Certification />} />

        {/* category */}
        <Route exact path="/ballistic-vests" element={<BallisticVests />} />
        <Route exact path="/ballistic-helmet" element={<BallisticHelmet />} />
        <Route exact path="/ballistic-protection" element={<BallisticProtection />} />
        <Route exact path="/ballistic-plates" element={<BallisticPlates />} />
        <Route exact path="/demining-clothing" element={<DeminingClothing />} />
        <Route exact path="/tactical-gears" element={<TacticalGears />} />
        <Route exact path="/platform-protection" element={<PlatformProtection />} />
        <Route exact path="/product/face-guard-visor" element={<FaceGuardVisor />} />
        <Route exact path="/product/nvg-shroud" element={<NvgShroud />} />
        <Route exact path="/product/helmet-pads" element={<Pads />} />
        <Route exact path="/product/harness" element={<Harness />} />
        <Route exact path="bespoke-solutions" element={<BespokeSolutions />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/lets-know-what-body-armour-is" element={<LetsKnowWhatBodyArmourLs />} />
        <Route exact path="/tactical-bullet-proof-plate-carrier-vest" element={<TacticalBulletProofPlateCarrierVest />} />
        <Route exact path="/ballistic-products-customization-bulk-ordering" element={<BallisticProductsCustomizationBulkOrdering />} />
        <Route exact path="/anti-riot-gear" element={<AntiRiotGear />} />
        {/*  */}
 {/* <Route exact path="/product/product/military-vest" element={<Armyvest />} />/> */}
         <Route exact path="/product/:id" element={<Product />} /> 
        {/* <Route exact path="/product/bespoke" element={<Bespoke />} />
        <Route exact path="/product/armed-vehicle" element={<Armedvehicle />} />
        <Route exact path="/product/front-open-molle-webbing-vest" element={<FrontOpenMolleWebbingVest />} />       
        <Route exact path="/product/tactical-vest" element={<AssaultVest />} />
        <Route exact path="/product/ballistic-flotation-vest" element={<FlotationVest />} />
        <Route exact path="/product/general-purpose-vest" element={<Gpv />} />
        <Route exact path="/product/k9" element={<K9 />} />
        <Route exact path="/product/quick-release-system" element={<QuickReleaseVest />} />
        <Route exact path="/product/rescuer-vest" element={<RescuerVest />} />
        <Route exact path="/product/savior-vest" element={<SaviorVest />} />
        <Route exact path="/product/torc" element={<Torc />} />
        <Route exact path="/product/valiant-tactical-carrier" element={<ValiantTacticalCarrier />} />
        <Route exact path="/product/vip-undervest" element={<VipVest />} />
        <Route exact path="/product/plate-carrier-vest-slick-i" element={<PlateCarrierVestSlickI />} />
        <Route exact path="/product/gauzy-vest" element={<Gauzyvest />} />
        <Route exact path="/product/overt-carrier" element={<Overtcarrier />} />
        <Route exact path="/product/mich-with-acc" element={<MichWithAcc />} />
        <Route exact path="/product/pasgt-helmets" element={<PasgtWithAcc />} />
        <Route exact path="/product/ballistic-goggles" element={<BallisticGoggles />} />
        <Route exact path="/product/military-vest" element={<Armyvest />} />
        <Route exact path="/product/special-force-helmet" element={<SfWithAcc />} />
        <Route exact path="/product/patka" element={<Patka />} />
        <Route exact path="/product/raps-helmet" element={<RapsHelmet />} />
        <Route exact path="/product/de-mining-apron" element={<DeminingApron />} />
        <Route exact path="/product/de-mining-vest" element={<DeminingVest />} />
        <Route exact path="/product/de-mining-suit" element={<DeminingSuit />} />
        <Route exact path="/product/armoured-vehicle" element={<LandProtection />} />
        <Route exact path="/product/naval-protection" element={<NavalProtection />} />
        <Route exact path="/product/air-protection" element={<AirProtection />} />
        <Route exact path="/product/step-in-visor" element={<StepInVisor />} />
        <Route exact path="/product/ballistic-visor-nij-level-iiia" element={<BallisticVisior />} />
        <Route exact path="/product/3-hole-skeleton" element={<HoleSkeleton />} />
        <Route exact path="/product/epp-pad" element={<EppPad />} />
        <Route exact path="/product/liner-Pad" element={<LinerPad />} />
        <Route exact path="/product/retention-system-with-occ-dial" element={<RetentionSystemwithOccDial />} />
        <Route exact path="/product/retention-system-with-worm-dial" element={<RetentionSystemWithWormDial />} />
       
        <Route exact path="/product/ballistic-folding-shield" element={<BallisticFoldingShield />} />
        <Route exact path="/product/composite-ballistic-panels" element={<CompositeBallisticPanels />} />
        <Route exact path="/product/bomb-blanket" element={<BombBlanket />} />
        <Route exact path="/product/ballistic-technical-fabric" element={<BallisticTechnicalFabric />} />
        <Route exact path="/product/ballistic-shield" element={<BallisticShield />} />
        <Route exact path="/product/spall-liner" element={<SpallLiner />} />
        <Route exact path="/product/face-guard-visor" element={<FaceGuardVisor />} />
       

        <Route exact path="/product/side-rails" element={<SideRails />} />
        <Route exact path="/product/tactical-trousers" element={<TacticalTrousers />} />
        <Route exact path="/product/tactical-uniform" element={<TacticalUniform />} />
        <Route exact path="/product/first-aid-kit" element={<FirstAidKit />} />
        <Route exact path="/product/vehicle-medical-bag" element={<VehicleMedicalBag />} />
        <Route exact path="/product/tactical-belt" element={<TacticalBelt />} />
        <Route exact path="/product/military-boots" element={<MilitaryShoes />} />
        
        <Route exact path="/product/rails-picatinny-with-adapters-bungee-cord" element={<SideRails />} />
        <Route exact path="/product/tactical-bag-for-weapons" element={<TacticalBagForWeapons/>} />
        <Route exact path="/product/counter-weight" element={<CounterWeight />} />
        <Route exact path="/product/tactical-belt" element={<TacticalBelt />} />
        <Route exact path="/product/mich-helmet" element={<MichWithAcc />} />
        <Route exact path="/product/mid-cut-with-acc" element={<MidCutWithAcc />} />
        
       
        <Route exact path="/lets-know-what-body-armour-is" element={<LetsKnowWhatBodyArmourLs />} />
        <Route exact path="/tactical-bullet-proof-plate-carrier-vest/" element={<TacticalBulletProofPlateCarrierVest />} />
        <Route exact path="/product/level-3-rectangular-1-45-kg" element={<Level3Rectangular145Kg />} />
        <Route exact path="/product/level-3-corner-cut-1-35-kg" element={<Level3CornerCut135Kg />} />
        <Route exact path="/product/level-3-Shooter-swimmer-cut-1-25-kg" element={<Level3ShooterSwimmerCut125Kg />} />
        <Route exact path="/product/level-iv-corner-cut-single-curve-2-70-Kg" element={<LevelLvCornerCutSingleCurve270Kg />} />
        <Route exact path="/product/level-iv-shooter-&-swimmer-cut-2-50-kg" element={<LevelLvShooterSwimmerCut250Kg />} />
        <Route exact path="/product/hard-armour-panellevel-iii" element={<HardArmourPanelLeveliii />} />
        <Route exact path="/product/hard-armour-panel-level-iii-2-9-kg" element={<HardArmourPanelLeveliii29kg />} />
        <Route exact path="/product/hard-armour-panel-level-iii-1-8-kg" element={<HardArmourPanelLeveliii18Kg />} />
        <Route exact path="/product/hard-armour-panel-level-iii-3-2-kg" element={<HardArmourPanelLeveliii32Kg />} />
        <Route exact path="/product/hard-armour-panel-level-iv-2-6-kg" element={<HardArmourPanelLeveliv26Kg />} />
        <Route exact path="/product/hard-armour-panel-level-iv-3-2-kg" element={<HardArmourPanelLeveliv32Kg />} /> */}

        
       <Route exact path="/product/ballistic-helmet-acc" element={<BallisticHelmetAcc />} />
        <Route exact path="/thankyou" element={<ThankYou />} />
        <Route path="*"  element={<PageNotFound />} status={404} />
        <Route path="/search"  element={<Home />} />
      </Routes>
      <MainFooter />

    </>

  );


}

export default App;

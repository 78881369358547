import React from 'react'
import Slider from "react-slick";
import { useEffect, useState, useRef } from "react";
import { useParams,Link } from 'react-router-dom';
import { API_URL } from "../utils/utility";
import axios from 'axios'

function ProductFAQ({slug}) {


    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    
    const slider1 = useRef(null);
    const slider2 = useRef(null);
    useEffect(() => {
      setNav1(slider1.current);
      setNav2(slider2.current);
      // getData(slug)
      console.log(slug)
      getData(slug)
    }, []);

    const [product, setProduct] =  useState([]);
    const [selectedSize, setSelectedSize] =  useState([]);
    const [selectedColor, setSelectedColor] =  useState([]);
    
    const params = useParams();
    // const { id: slug } = params;
    const id  = slug;
    // console.log(id)

    
     
    const getData = async (id) => {
      try {
        const { data } = await axios.get(`${API_URL}/APIs/Product/faq.php?page=${id}`  );
        
        setProduct(data)
    
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        
      }
    };

   
     
   

  return (
    <>
   
    {  product[0]?.heading &&
      <section className="faq-sec">
          <div className="container">
            <div className="faq-he"><h2>Frequently asked questions</h2></div>
            <div className="row">
              <div className="faq-accordion">
                <div className="row">
                  
                { product.map((p,i)=>{

return (
  i % 2 === 0 && (
       
                < >  
                 { product[i]?.heading &&               
                 <div className="col-lg-6" data-aos="fade-right" key={i}>
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id={"headingOne"+i}>
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne"+i} aria-expanded="false" aria-controls="collapseOne">
                         {product[i]?.heading}
                          </button>
                        </h2>
                        <div id={"collapseOne"+i} className="accordion-collapse collapse" aria-labelledby={"headingOne"+i} data-bs-parent="#accordionExample" style={{}}>
                          <div className="accordion-body">
                            <p>{product[i]?.content}</p>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  }
                  {/* /col-lg-6 */}

                 { product[i+1]?.heading &&
                  <div className="col-lg-6" data-aos="fade-left" key={i+1}>
                    <div className="accordion" id="accordionExample1">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id={"headingOne"+i+1}>
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne1"+i+1} aria-expanded="true" aria-controls="collapseOne1-1">
                          {product[i+1]?.heading}
                          </button>
                        </h2>
                        <div id={"collapseOne1"+i+1} className="accordion-collapse collapse " aria-labelledby={"headingOne"+i+1} data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                            {product[i+1]?.content}
                            </p>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div> 
                }
                  </>

)
)
})
}
                

                </div>
              </div>
            </div>
          </div>
        </section>
  }
     </>  
       
  )
}

export default ProductFAQ
import React from 'react'
 
import ProductDetail from "../../component/ProductDetail";
import ProductFAQ from '../../component/ProductFAQ';
import { useLocation } from 'react-router-dom';
import Slider from "react-slick";
import { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet"
import { Link } from 'react-router-dom'
import { BASE_URL } from '../../utils/utility';
import Certifcates from '../../component/Certifcates';
import TopSectionProduct from '../../component/TopSectionProduct';
function FaceGuardVisor() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

    
  const location = useLocation();
 
  const slug = location.pathname.split("/")[2];
  
  return ( 
    <div>
         <TopSectionProduct />
  
  <div className="verticale-slider">
  

    {/* left right */}
    <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex={0}>
      <section className="vest-left-box section" id="scroll1">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
              <div className="vest-left-img vest-right-img" data-aos="fade-right" data-aos-offset={100}>
              <img src="/images/Step-in_Visor.png" />
              </div>
            </div>
            <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
              <div className="vest-box-set" data-aos="fade-left" data-aos-offset={100}>
                <div className="vest-left-y-text">
                  <h2>STEP IN Visor</h2>
                  <p>Designed to flawlessly integrate with Night
                  Vision Goggles (NVGs), the Visor offers an
                  optimal solution for users seeking
                  comprehensive eye protection combined
                  with easy NVG accessibility. The STEP-IN
                  Visor assembly boasts removable gaskets
                  and tool-less interchangeable lenses,
                  allowing effortless customization to suit
                  diverse mission needs. Its low-profile
                  design fits all users and is fully compatible
                  with ballistic helmets equipped with addon Accessory Rail Connectors (ARCs).</p>
                  <div className="input-group mt-5">
                  <Link to="/product/step-in-visor" className="btn know-more-btn submit">View Product
                        <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                      </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* left right */}
      {/* left right */}
      <section className="vest-left-box vest-left-box2 section" id="scroll2">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
              <div className="vest-box-set-left" data-aos="fade-right" data-aos-offset={100}>
                <div className="vest-left-y-text">
                  <h2>Ballistic Visior</h2>
                  <p>The Demining Visor is coated with polycarbonate and PMMA fixed with TPU interlayer which is specially engineered to provide entire face protection from ballistic threats as well as blunt and fragmentation effects.

</p>
                  <div className="input-group mt-5">
                  <Link to="/product/ballistic-visor-nij-level-iiia" className="btn know-more-btn submit">View Product
                        <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                      </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
              <div className="vest-left-img" data-aos="fade-left" data-aos-offset={100}>
              <img src="/images/Ballistic_Visor.png" />
              </div>
            </div>
          </div>
        </div>
      </section>

          </div>
     
   
   
        
          <section className="nij-levels">
          <div className="container">

            {/* Certificates */}
            <Certifcates></Certifcates>
            {/* Certificates */}
          </div>
        </section>
      {/* faq */}
      <section className="faq-sec">
        <div className="container">
          <div className="faq-he"><h2>Frequently asked questions</h2></div>
          <div className="row">
            <div className="faq-accordion">
              <div className="row">
                <div className="col-lg-6" >
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          Are there any Level 4 ballistic helmets?
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">
                          <p>No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          What is a ballistic helmet used for?
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading3">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseTwo">
                          Is the helmet bulletproof?
                        </button>
                      </h2>
                      <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{/* /col-lg-6 */}
                <div className="col-lg-6">
                  <div className="accordion" id="accordionExample1">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne1-1">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1-1" aria-expanded="true" aria-controls="collapseOne1-1">
                          Can a ballistic helmet stop a bullet?
                        </button>
                      </h2>
                      <div id="collapseOne1-1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo1-2">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-2" aria-expanded="false" aria-controls="collapseTwo1-2">
                          Do Kevlar helmets expire?
                        </button>
                      </h2>
                      <div id="collapseTwo1-2" className="accordion-collapse collapse" aria-labelledby="headingTwo-2" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo1-3">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-3" aria-expanded="false" aria-controls="collapseTwo1-3">
                          What is the difference between Level 3 and Level 4 body armour? 
                        </button>
                      </h2>
                      <div id="collapseTwo1-3" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{/* /col-lg-6 */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    
    

    
    </div>

  )
}

export default FaceGuardVisor
import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
function Certification() {
  return (
    <div>
        <Helmet>
        <title>Indian Armour Certifications | Elevate Trust and Quality  </title>
        <meta name="description" content="Explore Indian Armour Certification Page to discover our commitment to excellence. Gain insights into our rigorous certification programs designed to ensure the highest standards of quality and expertise." />
        <link rel="canonical" href="https://www.indianarmour.com/certification" />
   
      </Helmet>
    <section className="inner-banner d-flex align-items-center " style={{backgroundImage: 'url("/images/Certificate.jpg")'}}>
      <div className="inner-banner-content1">
        <div className="container">
          <h1 className="text-white fw-bolder">Certifications</h1>
        </div>
      </div>
    </section>
    <section className="breadcrumb-inner pt-3  pb-5">
      <div className="container">
        <nav >
          <ol className="breadcrumb mb-0 justify-content-start">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Certifications</li>
          </ol>
        </nav>
      </div>
    </section>
    <section className="certification py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="certification-list">
              <div className="cl-logo">
                <img src="/images/Certifications-1.png" />
              </div>
              <div className="cl-content">
                <h4>National Institure of Justice</h4>
                <p>The National Institute of Justice (NIJ) is the United States Department of Justice’s research, development, and evaluation agency. The National Institute of Justice encourages the development of voluntary equipment performance standards as well as compliance testing.
                  Indian Armour Products are  NIJ Standards and is listed on the NIJ website as a certified and approved supplier http://www.justnet.org/. Our products are tested in both field level and lab-level environments.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="certification-list">
              <div className="cl-logo">
                <img src="/images/Certifications-2.png" />
              </div>
              <div className="cl-content">
                <h4>ISO 9001:2015</h4>
                <p>Indian Armour is certified and conforms to the ISO 9001:2015 standard.
                  We are a trusted manufacturer with over 2 decades of experience in the production of high-quality body armour, including a wide range of ballistic helmets, panels, as well as bullet, stab, and spike resistant vests.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="certification-list">
              <div className="cl-logo">
                <img src="/images/Certifications-3.png" />
              </div>
              <div className="cl-content">
                <h4>NTS Chesapeake</h4>
                <p>Chesapeake Testing is an industry leader in ballistic and armour protection systems testing. Chesapeake Testing, as an NVLAP and A2LA ISO/IEC 17025:2005 accredited laboratory, is authorised by the National Institute of Justice to undertake personal body armour testing to NIJ Standard-0101.06 and NIJ Standard-0115.00 Stab Resistance of personal body armour. Chesapeake Testing is also authorised to undertake helmet and hard and soft armour testing by the US Army Aberdeen Test Centre.
                  All Indian Armour products are rigorously and comprehensively tested by NTS Chesapeake.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}

export default Certification
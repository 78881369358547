import React from 'react'
 
import ProductDetail from "../../component/ProductDetail";
import ProductFAQ from '../../component/ProductFAQ';
import { useLocation } from 'react-router-dom';
import Slider from "react-slick";
import { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet"
import { Link } from 'react-router-dom'
import { BASE_URL } from '../../utils/utility';
import Certifcates from '../../component/Certifcates';
import TopSectionProduct from '../../component/TopSectionProduct';
function NvgShroud() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

    
  const location = useLocation();
 
  const slug = location.pathname.split("/")[2];
  
  return ( 
    <div>
       <TopSectionProduct />
  <div className="verticale-slider">
   

    {/* left right */}
    <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex={0}>
      <section className="vest-left-box section" id="scroll1">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
              <div className="vest-left-img vest-right-img" data-aos="fade-right" data-aos-offset={100}>
              <img src="/images/hole-skeleton.png" className='img-fluid' />
              </div>
            </div>
            <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
              <div className="vest-box-set" data-aos="fade-left" data-aos-offset={100}>
                <div className="vest-left-y-text">
                  <h2>3 Hole skeleton</h2>
                  <p>The NVG Shroud is an integrated System that mounts directly to the front of the helmet thereby providing the user with the direct center focused lighting while eliminating the need for auxiliary side mounted lights—reducing weight, encumbrances, snag hazards, and additional unnecessary equipment.

</p>
                  <div className="input-group mt-5">
                  <Link to="/product/3-hole-skeleton" className="btn know-more-btn submit">View Product
                        <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                      </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* left right */}
      {/* left right */}
      {/* // comment op
      <section className="vest-left-box vest-left-box2 section" id="scroll2">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
              <div className="vest-box-set-left" data-aos="fade-right" data-aos-offset={100}>
                <div className="vest-left-y-text">
                  <h2>Assault Vest </h2>
                  <p>Our tactical equipment has been designed conforming to the latest requirements of the modern warrior. Built out of rugged material designed to improve mission efficiency, to enhance survivability and lethality without compromising mobility.</p>
                  <div className="input-group mt-5">
                    <Link to="/assault-vest" className="btn know-more-btn submit">View Product
                      <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
              <div className="vest-left-img" data-aos="fade-left" data-aos-offset={100}>
                <img src="/images/vest-2.png" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* left right */}
      {/* left right */}
      {/* // comment op
        <section className="vest-left-box section" id="scroll3">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                <div className="vest-left-img vest-right-img" data-aos="fade-right" data-aos-offset={100}>
                  <img src="/images/vest-1.png" />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                <div className="vest-box-set" data-aos="fade-left" data-aos-offset={100}>
                  <div className="vest-left-y-text">
                    <h2>Flotation Vest</h2>
                    <p>The Neptune I model offers a tactical ballistic protection solution for users working on or around the water. It was designed with maritime security personnel or naval users in mind who require maximum protection with the flexibility of a standard tactical vest. Utilizing the BALCS cut style of soft armour, providing front, back and side protection; the Neptune I vest also has a detachable flotation collar with manual and automatic inflation.</p>
                    <div className="input-group mt-5">
                      <a href="#" className="btn know-more-btn submit">View Product
                        <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* left right */}
        {/* left right */}
        {/* // comment op
        <section className="vest-left-box vest-left-box2 section" id="scroll4">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                <div className="vest-box-set-left" data-aos="fade-right" data-aos-offset={100}>
                  <div className="vest-left-y-text">
                    <h2>Front Open Molle Webbing Vest</h2>
                    <p>The Protector MOLLE is designed such, that it is easy to don and quick to remove. With a simple front jacket style solution, it provides excellent protection, while remaining simple to use. It comes complete with MOLLE attachments and load out points, allowing full tactical use.</p>
                    <div className="input-group mt-5">
                      <a href="#" className="btn know-more-btn submit">View Product
                        <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                <div className="vest-left-img" data-aos="fade-left" data-aos-offset={100}>
                  <img src="/images/vest-2.png" />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* left right */}
        {/* left right */}
        {/* // comment op     
          <section className="vest-left-box section" id="scroll5">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                  <div className="vest-left-img vest-right-img" data-aos="fade-right" data-aos-offset={100}>
                    <img src="/images/vest-1.png" />
                  </div>
                </div>
                <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                  <div className="vest-box-set" data-aos="fade-left" data-aos-offset={100}>
                    <div className="vest-left-y-text">
                      <h2>Front open Molle Webbing Vest</h2>
                      <p>The Protector MOLLE is designed such, that it is easy to don and quick to remove. With a simple front jacket style solution, it provides excellent protection, while remaining simple to use. It comes complete with MOLLE attachments and load-out points, allowing full tactical use.</p>
                      <div className="input-group mt-5">
                        <a href="#" className="btn know-more-btn submit">View Product
                          <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* left right */}
          {/* left right */}
          {/* // comment op
          <section className="vest-left-box vest-left-box2 section" id="scroll6">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                  <div className="vest-box-set-left" data-aos="fade-right" data-aos-offset={100}>
                    <div className="vest-left-y-text">
                      <h2>GPV</h2>
                      <p>IA-General Purpose Vest is designed to provide an excellent Level IIIA protection without restricting the wearer’s movement. This vest provides front, back, shoulder & side ballistic protection as a standard vest.</p>
                      <div className="input-group mt-5">
                        <a href="#" className="btn know-more-btn submit">View Product
                          <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                  <div className="vest-left-img" data-aos="fade-left" data-aos-offset={100}>
                    <img src="/images/vest-2.png" />
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* left right */}
          </div>

  
          <section className="nij-levels">
          <div className="container">

            {/* Certificates */}
          <Certifcates></Certifcates>
            {/* Certificates */}
          </div>
        </section>
      {/* What are the NIJ Levels for NVG Shroud? */}
      {/* contact form */}
      {/* <section class="contact-us-sec" style="background-image:url('images/contact-bg.png');">
<div class="container">
  <div class="row">
     <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div class="contact-detail" data-aos="fade-right" data-aos-offset="300">
         <div class="">
           <div class="contact-1">
          <ul>
            <li class="contact-1-img"><img src="/images/icon-1.png"></li>
            <li>Call Us on<br> <a href="tel:9811268600">+91-9811268600</a> </li>
         </ul>
         </div>

         <div class="contact-1">
             <ul>
                 <li class="contact-1-img"><img src="/images/icon-2.png"></li>
                 <li>Email<br> <a href="mailto:info@indianarmour.com">info@indianarmour.com</a> </li>
             </ul>
         </div>
         </div>
        </div>
     </div>
     <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div class="conact-form-ballistic" data-aos="fade-left" data-aos-offset="300">
            <h2>Contact US</h2>
            <form class="ballistic-form">
                
                <div class="mb-3">

<input type="email" class="form-control"  placeholder="Name">
</div>

<div class="mb-3">

<input type="email" class="form-control"  placeholder="Email">
</div>

<div class="mb-3">

<input type="text" class="form-control"  placeholder="Location">
</div>

<div class="mb-3">
<textarea class="form-control" rows="5" placeholder="Message"></textarea>
</div>

<div class="mb-3">

<div class="input-group mt-5">
            <a href="#" class="btn know-more-btn submit">Submit
              <span class="input-group-text"><img src="/images/arrow.svg"></span>
            </a>
          </div>


</div>

            </form>
        </div>
     </div>
  </div>
</div>
</section> */}
      {/* contact form */}
      {/* faq */}
      <section className="faq-sec">
        <div className="container">
          <div className="faq-he"><h2>Frequently asked questions</h2></div>
          <div className="row">
            <div className="faq-accordion">
              <div className="row">
                <div className="col-lg-6" >
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          Are there any Level 4 ballistic helmets?
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">
                          <p>No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          What is a ballistic helmet used for?
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading3">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseTwo">
                          Is the helmet bulletproof?
                        </button>
                      </h2>
                      <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{/* /col-lg-6 */}
                <div className="col-lg-6">
                  <div className="accordion" id="accordionExample1">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne1-1">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1-1" aria-expanded="true" aria-controls="collapseOne1-1">
                          Can a ballistic helmet stop a bullet?
                        </button>
                      </h2>
                      <div id="collapseOne1-1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo1-2">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-2" aria-expanded="false" aria-controls="collapseTwo1-2">
                          Do Kevlar helmets expire?
                        </button>
                      </h2>
                      <div id="collapseTwo1-2" className="accordion-collapse collapse" aria-labelledby="headingTwo-2" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo1-3">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-3" aria-expanded="false" aria-controls="collapseTwo1-3">
                          What is the difference between Level 3 and Level 4 body armour? 
                        </button>
                      </h2>
                      <div id="collapseTwo1-3" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.</p> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{/* /col-lg-6 */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    
    

    
    </div>

  )
}

export default NvgShroud
import React from 'react'
import Slider from "react-slick";
import { useEffect, useState, useRef } from "react";
import { Link,useLocation } from 'react-router-dom';
import { BASE_URL } from '../utils/utility';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazy-load';
function Home() {
  const [banner, setBanner] = useState([]);
  const ImageURL = 'https://www.indianarmour.com/indianarmourcms/';
  useEffect(() => {

    fetch('https://www.indianarmour.com/indianarmourcms/APIs/Banner/')
      .then((res) => res.json())
      .then((banner) => {
        setBanner(banner);
        console.log(banner)
      })
      .catch((err) => {
        console.log(err);
      });

      
  }, []);
  const bannerSlider = {
    dots: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 2000,
  };



  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
      autoplaySpeed: 3000,
  };


  // const [nav1, setNav1] = useState();
  // const [nav2, setNav2] = useState();
  // const slider1 = useRef(null);
  // const slider2 = useRef(null);
  // useEffect(() => {
  //   setNav1(slider1.current);
  //   setNav2(slider2.current);
  // }, []);
  const location = useLocation();
  const slug1 = location.pathname.split("/")[1];
// console.log("sam"+ JSON.stringify(location.pathname.split("/")))
  return (
    <>
{/* {slug1 != 'undefined' || slug1 != '' || slug1 != "/"
    ?
    <></>: */}
    {/* <div className={slug1 != 'undefined' || slug1 != '' || slug1 != "/" ? "" :"Homepage"}> */}
    

   <Helmet>
    <title>Body Armour | Bulletproof Armour Manufacturer | Indian Armour </title>
    <meta name="description" content="India's largest and most vertically integrated body armour manufacturer; from yarn to weaving of ballistic fabric to finished products. Indian Armour's ballistic helmet, vest & plate offer complete protection against a wide range of ballistic threats." />
    <link rel="canonical" href="https://www.indianarmour.com/" />
    </Helmet>
      <section className="banner border-bottom position-relative">
        <div className="container">
          <div className="banner-slider" >
          <LazyLoad >
            <Slider {...bannerSlider} >
              {banner.map((item, i) => {
                return <div>
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-6">
                      <div className="banner-slider-left-content">
                        <div className="slider-heading">
                          {item.heading}
                        </div>
                        <p>
                          {item.content}
                        </p>
                        <div className="input-group">
                          <Link to={item.button_url} className="btn know-more-btn">
                            Know more
                            <span className="input-group-text">
                              {<img src="/images/arrow.svg"  width={15} height={10} loading="lazy"/>}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="banner-img">
                        
                      <picture>
                        <source media="(min-width: 992px)" srcset={ImageURL + item.banner_img} alt={item.mobile_banner_alt_text} className="img-fluid" loading="lazy"/>                      
                        <img src={ImageURL + item.mobile_banner_img} alt={item.mobile_banner_alt_text} className="img-fluid" width={288} height={220} loading="lazy"/>
                      </picture>
                   
                       
                      </div>
                    </div>
                  </div>
                </div>


              })}


            </Slider>
            </LazyLoad>
          </div>
          <div className="border-blk">
            <span className="border-height" />
            <span className="border-text">Welcome</span>
            <div></div>
          </div>
        </div>
      </section>

      <section className="about-us-blk pt-50 left-bg border-bottom bottom-right">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-6" />
            <div className="col-lg-6 col-sm-6">
              <div className="box" data-aos="slide-up">
                <img src="https://www.indianarmour.com/images/box.webp" loading="lazy" />
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-6"
              data-aos="fade-right"
              data-aos-offset={300}
            >
              <h2 className="heading">
                About Us
              </h2>
              <p className="description mb-50">
                Indian Armour is an ISO 9001:2015 certified company, manufacturing a specialized ballistic protection product range, with a team of 200 skilled and well-trained workforce. The manufacturing facility is spread over an area of more than 70,000 sq ft and it is located only an hour’s drive away from New Delhi.
              </p>
              <div className="input-group">
                <Link to="https://www.indianarmour.com/about-us" className="btn know-more-btn">
                  Know more
                  <span className="input-group-text">
                    <img src="https://www.indianarmour.com/images/arrow.svg"  width={15} height={10} loading="lazy"/>
                  </span>
                </Link>
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-6"
              data-aos="fade-left"
              data-aos-offset={300}
            >
              <div className="about-us-img">
                <img src="https://www.indianarmour.com/images/about-us.webp" className="img-fluid" alt="" width={570} height={490} loading="lazy"/>
              </div>
            </div>
          </div>
          <div className="border-blk" data-aos="fade-up">
            <span className="border-height" />
            <span className="border-text">About Us</span>
            <div></div>
          </div>
        </div>
      </section>

      <section className="our-product left-bg border-bottom pt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading" data-aos="fade-up" data-aos-duration={1000}>
                Body Armour Manufacturer
              </h2>
              <p className="description" data-aos="fade-up" data-aos-duration={1500} >
                Indian Armour is the world’s most vertically integrated body armour manufacturer; from yarn to weaving of ballistic fabric to finished products. Our product range includes Bullet Resistant Jackets, Bullet resistant Vests, Hard Armour Panels, Bullet Resistant Helmets, Bomb Suppression Blankets, Tactical & Combat Gear, and De-Mining Protection Gear.
              </p>

              <p className="description" data-aos="fade-up" data-aos-duration={1500} >
                Being an international supplier, we have a proven track record of supplying premium quality and high-performance ballistic products to countries in the EU, Africa, Middle East, and the Americas.
              </p>
              <p className="description" data-aos="fade-up" data-aos-duration={1500} >
                We are a high-quality specialised manufacturer of armoured vehicles made of composites panels that are much lighter and stronger than steel. These vehicles are your best bet against ambush attacks and active shooter scenarios providing multi-threat capabilities.
              </p>
              <p className="description" data-aos="fade-up" data-aos-duration={1500} >
                We manufacture premium quality body armour that includes a large range of ballistic helmets, panels, bullet, stab, and spike-resistant vests. These vests are well-suited for military personnel, law enforcement officials, federal agencies, and civilians. These products can protect against multiple types of ammunition such as handguns, rifles, and even automatic guns.
              </p>
            </div>


            <div
              className="col-lg-4 col-sm-4"
              data-aos="fade-up"
              data-aos-duration={1000}
            >
              <Link to="https://www.indianarmour.com/ballistic-vests">
              <div className="product-list">
                <div className="product-img">
                  <h3 className="product-heading">Ballistic Vest</h3>
                  <img src="https://www.indianarmour.com/images/Vest.webp" className="img-fluid" alt="" width={416} height={555} loading="lazy"/>
                </div>
                <div className="product-hover">
                  <img src="https://www.indianarmour.com/images/product-arrow.png" className="hover-img" width={204} height={162} loading="lazy"/>
                  <h3 className="product-hover-heading">Ballistic Vest</h3>
                  <p>
                    Our ballistic vests are made of premium hardwearing fabric and are designed to meet the specific needs of the wearer. All our personal protection armour products are rigorously tested to ensure that they meet the NIJ standards.
                  </p>
                </div>
              </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-sm-4"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <Link to="https://www.indianarmour.com/ballistic-plates">  
              <div className="product-list">
                <div className="product-img">
                  <h3 className="product-heading">Ballistic Plates</h3>
                  <img src="https://www.indianarmour.com/images/Plates.webp" className="img-fluid" alt=""  width={416} height={555} loading="lazy"/>
                </div>
                <div className="product-hover">
                  <img src="https://www.indianarmour.com/images/product-arrow.png" className="hover-img" width={204} height={162} loading="lazy"/>
                  <h3 className="product-hover-heading">Ballistic Plates</h3>
                  <p>
                    Hard armour plates work in conjunction with the interior armour pack to stop a bullet from penetration or stand alone in a plate carrier vest. These hard armour plates provide protection against high-velocity rounds.
                  </p>
                </div>
              </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-sm-4"
              data-aos="fade-up"
              data-aos-duration={2000}
            >
              <Link to="https://www.indianarmour.com/ballistic-helmet">
              <div className="product-list">
                <div className="product-img">
                  <h3 className="product-heading">Ballistic Helmet</h3>
                  <img src="https://www.indianarmour.com/images/Helmet.webp" className="img-fluid" alt=""  width={416} height={555} loading="lazy"/>
                </div>
                <div className="product-hover">
                  <img src="https://www.indianarmour.com/images/product-arrow.png" className="hover-img" width={204} height={162} loading="lazy"/>
                  <h3 className="product-hover-heading">Ballistic Helmet</h3>
                  <p>
                    Our ballistic helmets have been specifically designed for use by civil police, military, SWAT, and Special Forces officers. These are also essential for use by para-military forces, federal agencies, first responders as well.
                  </p>
                </div>
              </div>
              </Link>
            </div>
          </div>
          <div className="collapse " id="collapseExample">
            <div className="row">
              <div
                className="col-lg-4 col-sm-4"
                data-aos="fade-up"
                data-aos-duration={1000}
              >
                <Link to="https://www.indianarmour.com/platform-protection">
                <div className="product-list">
                  <div className="product-img">
                    <h3 className="product-heading">Platform Protection</h3>
                    <img src="https://www.indianarmour.com/images/Vehicle.webp" className="img-fluid" alt=""  width={416} height={555} loading="lazy"/>
                  </div>
                  <div className="product-hover">
                    <img src="https://www.indianarmour.com/images/product-arrow.png" className="hover-img" width={204} height={162} loading="lazy"/>
                    <h3 className="product-hover-heading">Platform Protection</h3>
                    <p>
                      We specialise in offering state-of-the-art intelligent solutions for vehicle, naval, and aircraft armour. We are dedicated to providing the highest level of safety & security for your valuable assets using our cutting-edge technologies.
                    </p>
                  </div>
                </div>
                </Link>
              </div>
              <div
                className="col-lg-4 col-sm-4"
                data-aos="fade-up"
                data-aos-duration={1500}
              >
                <Link to="https://www.indianarmour.com/product/ballistic-shield">
                <div className="product-list">
                  <div className="product-img">
                    <h3 className="product-heading">Ballistic Shield</h3>
                    <img src="https://www.indianarmour.com/images/Shield.webp" className="img-fluid" alt=""  width={416} height={555} loading="lazy"/>
                  </div>
                  <div className="product-hover">
                    <img src="https://www.indianarmour.com/images/product-arrow.png" className="hover-img" width={204} height={162} loading="lazy"/>
                    <h3 className="product-hover-heading">Ballistic Shield</h3>
                    <p>
                      Our range of ballistic shields incorporates the latest materials technology and a handle system that has been ergonomically developed. They are available in a standard range of sizes & in a number of different protection levels.
                    </p>
                  </div>
                </div>
                </Link>
              </div>
              <div
                className="col-lg-4 col-sm-4"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <Link to="https://www.indianarmour.com/product/ballistic-helmet-acc">
                <div className="product-list">
                  <div className="product-img">
                    <h3 className="product-heading">Ballistic Helmet Accessories</h3>
                    <img src="https://www.indianarmour.com/images/Helmet_Accessories.webp" className="img-fluid" alt=""  width={416} height={555} loading="lazy"/>
                  </div>
                  <div className="product-hover">
                    <img src="https://www.indianarmour.com/images/product-arrow.png" className="hover-img" width={204} height={162} loading="lazy"/>
                    <h3 className="product-hover-heading">
                      Ballistic Helmet Accessories
                    </h3>
                    <p>
                      For our Ballistic Helmets, we have a wide range of accessories to increase the capability and effectiveness, like- Side rails, Side Protection, Pads, NVG Mount, Light, Headphone, Goggles, Harness, Counter weight, etc.
                    </p>
                  </div>
                </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="text-center py">
            <div className="input-group justify-content-center" data-aos="zoom-in">
              <a
                href="#"
                className="btn know-more-btn  emp collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
              >
                Explore More Products
                <span className="input-group-text">
                  <i className="fa-solid fa-plus" />
                </span>
              </a>
            </div>
          </div>

          <div className="border-blk" data-aos="fade-up" data-aos-offset={0}>
            <span className="border-height" />
            <span className="border-text">Our Products</span>
          </div>
        </div>
      </section>

      <section className="sps-blk left-bg border-bottom pt-100 pb-50 top-right">
        <div className="container">
          <h2 className="heading" data-aos="fade-up" data-aos-duration={1000}>
            Smart Platform Solution
          </h2>
          <div className="row g-0" data-aos="fade-up" data-aos-duration={1500}>
            <div className="col-lg-4 col-sm-4">
              <ul className="nav nav-pills flex-column">
                <li className="nav-item">
                  <a className="nav-link active" data-bs-toggle="pill" href="#home">
                    <div className="sps-icon">
                      <img src="https://www.indianarmour.com/images/sps-icon1-grey.svg" className='grey-icon' width={68} height={68} loading="lazy"/>
                      <img src="https://www.indianarmour.com/images/sps-icon1-red.svg" className='red-icon' width={68} height={68} loading="lazy"/>
                      <span>
                        Airborne <br /> Systems
                      </span>
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="pill" href="#menu1">
                    <div className="sps-icon">
                      <img src="https://www.indianarmour.com/images/sps-icon2-grey.svg" className='grey-icon' width={68} height={68} loading="lazy"/>
                      <img src="https://www.indianarmour.com/images/sps-icon2-red.svg" className='red-icon' width={68} height={68} loading="lazy"/>
                      <span>
                        {" "}
                        Naval <br /> Systems
                      </span>
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="pill" href="#menu2">
                    <div className="sps-icon">
                      <img src="https://www.indianarmour.com/images/sps-icon3-grey.svg" className='grey-icon' width={68} height={68} loading="lazy"/>
                      <img src="https://www.indianarmour.com/images/sps-icon3-red.svg" className='red-icon' width={68} height={68} loading="lazy"/>
                      <span>
                        {" "}
                        Land <br /> Systems
                      </span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-8  col-sm-8 border">
              <div className="tab-content">
                <div className="tab-pane container active" id="home">
                  <div className="sps-content">
                    <div className="row align-items-center p-4">
                      <div className="col-lg-6">
                        <h3>Airborne Systems</h3>
                        <p>
                          Having adequate armour systems for aircraft is crucial in a time when aerial threats are growing more sophisticated. Modern aircraft protection options from Indian Armour are strong yet lightweight. Our solutions are built to withstand a variety of dangers and offer improved protection for personnel and aircraft during crucial operations.
                        </p>
                        <Link to="https://www.indianarmour.com/product/air-protection" className="know-more ">
                          Know More <img src="https://www.indianarmour.com/images/arrow.svg" width={15} height={10} loading="lazy"/>
                        </Link>
                      </div>
                      <div className="col-lg-6">
                        <div className="sps-img">
                          <img src="https://www.indianarmour.com/images/airborne-systems.png" className="img-fluid" alt="" width={383} height={350} loading="lazy"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane container fade" id="menu1">
                  <div className="sps-content">
                    <div className="row align-items-center p-4">
                      <div className="col-lg-6">
                        <h3>Naval Systems</h3>
                        <p>
                          Protecting naval vessels is crucial when it comes to naval operations. Modern naval armour solutions that Indian Armour offers increase the capabilities of naval fleets to withstand contemporary threats. Our capabilities include designing and integrating armour systems that guarantee the security of the crew and essential equipment on board.
                        </p>
                        <Link to="https://www.indianarmour.com/product/naval-protection" className="know-more ">
                          Know More <img src="https://www.indianarmour.com/images/arrow.svg"  width={15} height={10} loading="lazy"/>
                        </Link>
                      </div>
                      <div className="col-lg-6">
                        <div className="sps-img">
                          <img src="https://www.indianarmour.com/images/naval-systems.png" className="img-fluid" alt="" width={383} height={350} loading="lazy"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane container fade" id="menu2">
                  <div className="sps-content">
                    <div className="row align-items-center p-4">
                      <div className="col-lg-6">
                        <h3>Land Systems</h3>
                        <p>
                          We specialise in offering a variety of advanced vehicle armour systems. It is essential to invest in strong and dependable armour given the growing risks that both personal and commercial vehicles must contend with. Our team of professionals works directly with clients to comprehend their particular demands and provide solutions that are specifically tailored to fit those objectives.
                        </p>
                        <Link to="https://www.indianarmour.com/product/armoured-vehicle" className="know-more ">
                          Know More <img src="https://www.indianarmour.com/images/arrow.svg"  width={15} height={10} loading="lazy"/>
                        </Link>
                      </div>
                      <div className="col-lg-6">
                        <div className="sps-img">
                          <img src="https://www.indianarmour.com/images/land-systems.png" className="img-fluid" alt="" width={383} height={350} loading="lazy"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-blk" data-aos="fade-up" data-aos-duration={500}>
            <span className="border-height" />
            <span className="border-text">Smart Platform Solution</span>
            <div></div>
          </div>
        </div>
      </section>
      <section className="featured-solution border-bottom pt-50 pb-100 left-bottom right-black-bg">
        <div className="container">
          <h2 className="heading" data-aos="fade-up" data-aos-duration={1000}>
            Featured Solutions
          </h2>
          <div className="row">
            <div className="col-lg-12">
              <div className="featured-solution-slider">

                {/* <div
                  className="slider-nav"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                >
                  <Slider asNavFor={nav1}
                    ref={slider2}
                    slidesToShow={3}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    centerMode={true}
                    centerPadding="0"
                  >
                    <div>
                      <div className="fs-detail">
                        <img
                          src="/images/quick-releasevest-slider.webp"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div>
                      <div className="fs-detail">
                        <img
                             src="/images/rescuer-vest-slider.webp"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div>
                      <div className="fs-detail">
                        <img
                        src="/images/vip-vest-slider.webp"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    
                    <div>
                      <div className="fs-detail">
                        <img
                          src="/images/Ballistic-Folding-260.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div>
                      <div className="fs-detail">
                        <img
                          src="/images/Bomb_Blanket-260.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </Slider>
                </div> */}


                <div
                  className="slider-for"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <Slider {...settings}>
                    <div>
                      <div className="fs-list">
                        <div className="row g-0">
                          <div className="col-lg-6 col-sm-6">
                            <div className="fs-list-content">
                              <h3>Quick Release Vest</h3>
                              <p>
                                In designing the Tactical Vest, we have taken into consideration the importance in the quick removal of a vest for a soldier whenever required. With add on pouches on the tactical vest, one can store magazines, bulletproof boards, maps, etc. The vest is equipped with MOLLE system specifically designed for combat applications in demanding situations.
                              </p>
                              <Link to="https://www.indianarmour.com/product/quick-release-system" className="know-more ">
                                Know More <img src="https://www.indianarmour.com/images/arrow.svg"  width={15} height={10} loading="lazy"/>
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-6">
                            <div className="fs-list-img">
                              <img
                             src="https://www.indianarmour.com/images/quick-releasevest-slider.webp"
                                className="img-fluid"
                                alt=""
                                width={350}
                                height={350}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="fs-list">
                        <div className="row g-0">
                          <div className="col-lg-6 col-sm-6">
                            <div className="fs-list-content">
                              <h3>Rescuer Vest</h3>
                              <p>
                                Half Body Bomb Suit is designed using state of art material which provide high level of protection with easy don and doff feature. Additionally, it has removable collar, groin protector,shoulder and upper arm protec- tors which allows freedom of movement.
                              </p>
                              <Link to="https://www.indianarmour.com/product/rescuer-vest" className="know-more ">
                                Know More <img src="https://www.indianarmour.com/images/arrow.svg"  width={15} height={10} loading="lazy"/>
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-6">
                            <div className="fs-list-img">
                              <img
                              src="https://www.indianarmour.com/images/rescuer-vest-slider.webp"
                                className="img-fluid"
                                alt=""
                                width={350}
                                height={350}
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="fs-list">
                        <div className="row g-0">
                          <div className="col-lg-6 col-sm-6">
                            <div className="fs-list-content">
                              <h3>VIP Vest</h3>
                              <p>
                                This new bulletproof under vest is a state of art product designed for discretion and flexibility. The anatomical flex points and breathable fabric give this one piece design the optimal comfort when used in hot climate zones. A discreet protection solution for VIP protection and covert security applications.
                              </p>
                              <Link to="https://www.indianarmour.com/product/vip-undervest" className="know-more ">
                                Know More <img src="https://www.indianarmour.com/images/arrow.svg"  width={15} height={10} loading="lazy"/>
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-6">
                            <div className="fs-list-img">
                              <img
                                  src="https://www.indianarmour.com/images/vip-vest-slider.webp"
                                className="img-fluid"
                                alt=""
                                width={350}
                                height={350}
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
      

                    <div>
                      <div className="fs-list">
                        <div className="row g-0">
                          <div className="col-lg-6 col-sm-6">
                            <div className="fs-list-content">
                              <h3>Ballistic Folding Shield</h3>
                              <p>
                                IA-Ballistic Briefcase is a discreet close pro-tection and a rapid-deployment solution. It’s
                                non-threatening appearance and easy carry feature makes it ideal for executives,close protection officers and VIP body-
                                guards. It can be unfolded rapidly with one hand to provide a sizeable line of defense for ballistic and fragmentation threats.
                              </p>
                              <Link to="https://www.indianarmour.com/product/ballistic-folding-shield" className="know-more ">
                                Know More <img src="https://www.indianarmour.com/images/arrow.svg"  width={15} height={10} loading="lazy"/>
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-6">
                            <div className="fs-list-img">
                              <img
                                src="https://www.indianarmour.com/images/Ballistic-Folding-260.webp"
                                className="img-fluid"
                                alt=""
                                width={350}
                                height={350}
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="fs-list">
                        <div className="row g-0">
                          <div className="col-lg-6 col-sm-6">
                            <div className="fs-list-content">
                              <h3>Bomb Blanket</h3>
                              <p>
                                A light weight & portable ballistic and fragmentation resistant blanket, which can be
                                configured in any shape & size, & can be easily folded over, or placed on top of any
                                ballistic threat. It gives emergency protection against blasts & fragments in the event of an
                                explosive device detonating before it can be neutralized.
                              </p>
                              <Link to="https://www.indianarmour.com/product/bomb-blanket" className="know-more ">
                                Know More <img src="https://www.indianarmour.com/images/arrow.svg"  width={15} height={10} loading="lazy"/>
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-6">
                            <div className="fs-list-img">
                              <img 
                                src="https://www.indianarmour.com/images/Bomb_Blanket-260.webp"
                                className="img-fluid bb-image"
                                alt=""
                                width={350}
                                height={350}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div className="border-blk" data-aos="fade-up">
            <span className="border-height" />
            <span className="border-text">Featured Solution</span>
            <div></div>
          </div>
        </div>
      </section>
      <section className="exploring-green border-bottom pt-50 pb-100 right-black-bg">
        <div className="container">
          <h2 className="heading" data-aos="fade-up" data-aos-duration={1000}>Exploring Indian Armour's Green Packaging Initiatives</h2>
          <div className="row">
            <div className="exploring-green-sec">
              <p>Sustainable solutions are now essential in the modern world, where environmental concerns are on the rise. Green packaging is one sector that has made tremendous progress. Businesses are becoming more aware of the value of eco-friendly packaging in lowering their environmental effect and satisfying environmentally concerned customers’ requests. As a company dedicated to eco-friendly operations, Indian Armour is a shining example in this field.</p>
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div className="exploring-green-box">
                    <div className="exploring-green-img">
                      <img src="https://www.indianarmour.com/images/green-3.png" width={40} height={40} loading="lazy"/>
                    </div>
                    <h2>Reduce   </h2>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="exploring-green-box">
                    <div className="exploring-green-img">
                      <img src="https://www.indianarmour.com/images/green-2.png"  width={40} height={40} loading="lazy"/>
                    </div>
                    <h2>Reuse    </h2>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="exploring-green-box">
                    <div className="exploring-green-img">
                      <img src="https://www.indianarmour.com/images/green-1.png"  width={40} height={40} loading="lazy"/>
                    </div>
                    <h2>Recycle</h2>
                  </div>
                </div>

                <div className="col-lg-3 col-6">
                  <div className="exploring-green-box">
                    <div className="exploring-green-img">
                      <img src="https://www.indianarmour.com/images/repurpose.png"  width={40} height={40} loading="lazy"/>
                    </div>
                    <h2>Repurpose</h2>
                  </div>
                </div>


              </div>
              {/* <p>Indian Armour is a high-quality specialized manufacturer of armoured vehicles. Our Armoured Vehicles are manufactured to the highest standard and ballistic integrity. These vehicles are produced under strict manufacturing conditions using the highest quality of ballistic materials. These vehicles are produced under strict manufacturing conditions using the highest quality of ballistic materials.</p> */}
            </div>
          </div>
          <div className="border-blk " data-aos="fade-up"><span className="border-height" /><span className="border-text">Green Packaging Initiatives</span><div>
          </div>
          </div>
        </div>
      </section>
      <section className="our-process position-relative pt-5">
        <div className="container">
          <h2 className="heading" data-aos="fade-up" data-aos-duration={1000}>
            Our Process
          </h2>
        </div>
        <div className="container-fluid p-0 position-relative">
          <img src="https://www.indianarmour.com/images/video-bg.webp" className="img-fluid" alt="" loading="lazy" />
          <a
            href="https://www.youtube.com/watch?v=79pc_hdmCIU"
            className="glightbox video-pop-up"
            data-fancybox=""
            data-aos="zoom-in"
            target='_blank'
          >
            <i className="fa-sharp fa-solid fa-play" />
          </a>
          <div className="video-content" data-aos="fade-left">
            <h4> Yarn to Finished Products</h4>
            We, at Indian Armour, have a worldwide reputation for innovation and quality as a vertically integrated firm from yarn to finished product, offering a comprehensive range of high-performance technical textiles for critical military applications to different markets across the world.
          </div>
        </div>
        <div className="container">
          <div className="border-blk" data-aos="fade-up" data-aos-duration={500}>
            <span className="border-height" />
            <span className="border-text">Video</span>
            <div></div>
          </div>
        </div>
      </section>
    {/* </div> */}
{/* } */}
</>
  )
}

export default Home
import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useParams, useLocation   } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageNotFound from './PageNotFound';

function BlogDetail() {
const  [data, setData] = useState([])
const {slug} = useParams();

const canonical = "https://www.indianarmour.com/"+ slug;

const facebook = "https://www.facebook.com/sharer/sharer.php?u="+ slug;

// const status ="404";
const location = useLocation();
  const slug1 = location.pathname.split("/")[1];
//console.log(slug1)
const ImageURL = 'https://www.indianarmour.com/indianarmourcms/';
  useEffect(() => {
      //fetch(`https://jsonplaceholder.typicode.com/posts/${slug}`)
      fetch(`https://www.indianarmour.com/indianarmourcms/APIs/Blog/blogdetail.php?slug=${slug1}`)
     .then((res) => res.json())
     .then((data) => {
     setData(data);
     console.log(data[0].title)
    //console.log("Test"+slug1)


     })
     .catch((err) => {
     console.log(err);
     });
     }, []);

    // const  posted_by = data[0]?.posted_by;

//alert(posted_by);


  return (


    
    <div>
      <Helmet>
    <link rel="canonical" href={canonical} />
    <title>{data[0]?.meta_title}</title>
    <meta name="description" content={data[0]?.meta_desc} />
    </Helmet> 
    {/* {data[0]?.title? <BlogDetailData /> :<PageNotFound /> } */}
      {/* {data[0]?.slug==slug1? */}

     {/* {status=="404"? */}
   


     {data[0]?.Status=="True" ? 


   
 
   

   <div>
    <section className="inner-banner d-flex align-items-center justify-content-center" style={{backgroundImage: 'url("../images/blog-banner.jpg")'}}>
      <div className="inner-banner-content1">
        <div className="container">
          <h1 className="text-white fw-bolder">Blogs</h1>
        </div>
      </div>
    </section>
    

    <section className="breadcrumb-inner pt-3  pb-5">
      <div className="container">
        <nav>
          <ol className="breadcrumb mb-0 justify-content-start">
            <li className="breadcrumb-item">
             <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
             <Link to="/blog">Blog</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
           {data[0]?.title}
            </li>
          </ol>
        </nav>
      </div>
    </section>
  
 
    <section className="blog-detail-blk pb-100 ">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <p><span className="blog-detail-date">{data[0]?.publish_date}</span></p>
            <h2>{data[0]?.title}</h2>
            <div className="blog-detail-img">

              <img src={ImageURL + data[0]?.image} className="img-fluid w-100" />
            </div>
            <p dangerouslySetInnerHTML={{__html: data[0]?.content}}></p>
            
          </div>
          {data[0]?.posted_by &&
          <p><span className="blog-detail-date">Posted By- {data[0]?.posted_by}</span></p>
          }
          <div className='share'>Share: 
            { <Link to={`http://www.linkedin.com/shareArticle?mini=true&url=${canonical}`} target='_blank'> <i class="fa-brands fa-linkedin fa-xl" title="Linkedin"></i> </Link> }
            <Link to={`https://www.facebook.com/sharer/sharer.php?u=${canonical}&title=${data[0]?.title}`} target='_blank'> <i class="fa-brands fa-facebook fa-xl" title="Facebook"></i> </Link>
            {/* <Link to={`https://www.facebook.com/sharer/sharer.php?u=${canonical}`} target='_blank'><i class="fa-brands fa-instagram fa-xl" title="Instagram"></i> </Link> */}
            <Link to={`https://twitter.com/intent/tweet?text=${data[0]?.title}...'+${canonical}`} target='_blank'> <i class="fa-brands fa-twitter fa-xl" title="Twitter"></i> </Link>  
           {/* <Link to=''><i class="fa fa-tumblr" aria-hidden="true" title="Tumblr"></i> </Link>
            <Link to={`https://pinterest.com/pin/create/link/?url=${canonical}&title=${data[0]?.title}`}  target='_blank'> <i class="fa fa-pinterest" aria-hidden="true" title="Pinterest"></i></Link>*/ }
          </div>
          <Link to='/blog' className="back"><i className="fa-solid fa-arrow-left-long" /> Back </Link>
        </div>
      </div>

    </section>
    </div>
    : "" }

{data[0]?.Status=="False" ? <PageNotFound/> : ""}



  </div>
 



  )
  
}

export default BlogDetail
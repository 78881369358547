import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { render } from "react-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

      <BrowserRouter>
        <App />
    </BrowserRouter>

);
// const rootElement = document.getElementById("root");
// hydrate( <BrowserRouter>  <App /> </BrowserRouter> , rootElement);

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   render(Appp , rootElement);
// } else {
//   render(Appp, rootElement);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function AboutUs() {

  return (
    <div>
      <Helmet>
        <title>Indian Armour is an ISO 9001:2015 certified company </title>
        <meta name="description" content="Indian Armour specializes in manufacturing ballistic protection products, with two decades of experience and a team of 200 skilled and well-trained professionals." />
        <link rel="canonical" href="https://www.indianarmour.com/about-us" />
      </Helmet>
    <section className="inner-banner d-flex align-items-center justify-content-center" style={{backgroundImage: 'url("/images/About_Us_Banner.png")'}}>
      <div className="inner-banner-content1">
        <div className="container">
          <h1 className="text-white fw-bolder">About Us</h1>
        </div>
      </div>
    </section>
    <section className="breadcrumb-inner pt-3  pb-5">
      <div className="container">
        <nav  aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 justify-content-start">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page">About Us</li>
          </ol>
        </nav>
      </div>
    </section>
    {/* about us */}
    <section className="about-us right-black-bg py-5">
      <div className="container">
        <div className="row co-reverse">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="about-text" data-aos="fade-right">
              <h1>About Us</h1>
              <div className="about-img" data-aos="fade-left">
              <img src="/images/about-1.png" className='img-fluid' alt='armour body vest' />
            </div>
              <p>Indian Armour offers an excellent range of protective products such as Composite Armour Panels, Bullet Resistant Jackets, Helmets, Bomb Suppression Blankets, Bomb Disposal Suits, Combat Boots, Tactical Kit Bags, and fully tried and tested Armoured Vehicles.</p>
              <p>Indian Armour is an ISO 9001:2015 certified company, Manufacturing Specialized ballistic protection product range, with a team of 200 skilled and well-trained workforce. The manufacturing facility is spread over an area of more than 70,000 sq ft and it is located only an hour’s drive away from New Delhi.
                Indian Armour is the most vertically integrated manufacturing company in the world thus making us a very competitive and prosperous organization. Indian Armour, as an elite manufacturer of ballistic protection products, has remained committed to using cutting-edge technology for developing products for both the army and the police, that are lighter, better, and cost-effective. The personal protection products are designed for maximum safety in diverse operational conditions.</p>
                <p>All our products are rigorously and comprehensively tested both in the field and in recognized international laboratories of Europe and the USA. The in-house Product Development Team responds swiftly to new customer requirements and challenges. Indian Armour has continuously led the field in creating better and more cost-effective ways to save lives around the world.</p>
            </div>
          </div>
   
        </div>
      
      </div>
    </section>
  

    <section className="our-product right-black-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="heading" data-aos="fade-up" data-aos-duration={1000}>Explore Our Products</h2>
            <p className="description" data-aos="fade-up" data-aos-duration={1500}>Indian Armour is the world’s most vertically integrated body armour manufacturer; from yarn to weaving of ballistic Fabric to finished products. Our product range includes Bullet Resistant Jackets, Bullet resistant Vests, Hard Armour Panels, Bullet Resistant Helmets, Bomb Suppression Blankets, Tactical &amp; Combat Gear, and De-Mining Protection Gear.</p>
          </div>


          <div
              className="col-lg-4 col-sm-4"
              data-aos="fade-up"
              data-aos-duration={1000}
            >
              <Link to="/ballistic-vests">
              <div className="product-list">
                <div className="product-img">
                  <h3 className="product-heading">Ballistic Vest</h3>
                  <img src="/images/Vest.png" className="img-fluid" alt="" />
                </div>
                <div className="product-hover">
                  <img src="/images/product-arrow.png" className="hover-img" />
                  <h3 className="product-hover-heading">Ballistic Vest</h3>
                  <p>
                    Our ballistic vests are made of premium hardwearing fabric and are designed to meet the specific needs of the wearer. All our personal protection armour products are rigorously tested to ensure that they meet the NIJ standards.
                  </p>
                </div>
              </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-sm-4"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <Link to="/ballistic-plates">  
              <div className="product-list">
                <div className="product-img">
                  <h3 className="product-heading">Ballistic Plates</h3>
                  <img src="/images/Plates.webp" className="img-fluid" alt="" />
                </div>
                <div className="product-hover">
                  <img src="/images/product-arrow.png" className="hover-img" />
                  <h3 className="product-hover-heading">Ballistic Plates</h3>
                  <p>
                    Hard armour plates work in conjunction with the interior armour pack to stop a bullet from penetration or stand alone in a plate carrier vest. These hard armour plates provide protection against high-velocity rounds.
                  </p>
                </div>
              </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-sm-4"
              data-aos="fade-up"
              data-aos-duration={2000}
            >
              <Link to="/ballistic-helmet">
              <div className="product-list">
                <div className="product-img">
                  <h3 className="product-heading">Ballistic Helmet</h3>
                  <img src="/images/Helmet.png" className="img-fluid" alt="" />
                </div>
                <div className="product-hover">
                  <img src="/images/product-arrow.png" className="hover-img" />
                  <h3 className="product-hover-heading">Ballistic Helmet</h3>
                  <p>
                    Our ballistic helmets have been specifically designed for use by civil police, military, SWAT, and Special Forces officers. These are also essential for use by para-military forces, federal agencies, first responders as well.
                  </p>
                </div>
              </div>
              </Link>
            </div>
          </div>
          <div className="collapse " id="collapseExample">
            <div className="row">
              <div
                className="col-lg-4 col-sm-4"
                data-aos="fade-up"
                data-aos-duration={1000}
              >
                <Link to="/platform-protection">
                <div className="product-list">
                  <div className="product-img">
                    <h3 className="product-heading">Platform Protection</h3>
                    <img src="/images/Vehicle.png" className="img-fluid" alt="" />
                  </div>
                  <div className="product-hover">
                    <img src="/images/product-arrow.png" className="hover-img" />
                    <h3 className="product-hover-heading">Platform Protection</h3>
                    <p>
                      We specialise in offering state-of-the-art intelligent solutions for vehicle, naval, and aircraft armour. We are dedicated to providing the highest level of safety & security for your valuable assets using our cutting-edge technologies.
                    </p>
                  </div>
                </div>
                </Link>
              </div>
              <div
                className="col-lg-4 col-sm-4"
                data-aos="fade-up"
                data-aos-duration={1500}
              >
                <Link to="/product/ballistic-shield">
                <div className="product-list">
                  <div className="product-img">
                    <h3 className="product-heading">Ballistic Shield</h3>
                    <img src="/images/Shield.png" className="img-fluid" alt="" />
                  </div>
                  <div className="product-hover">
                    <img src="/images/product-arrow.png" className="hover-img" />
                    <h3 className="product-hover-heading">Ballistic Shield</h3>
                    <p>
                      Our range of ballistic shields incorporates the latest materials technology and a handle system that has been ergonomically developed. They are available in a standard range of sizes & in a number of different protection levels.
                    </p>
                  </div>
                </div>
                </Link>
              </div>
              <div
                className="col-lg-4 col-sm-4"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
              <Link to="/product/ballistic-helmet-acc">
                <div className="product-list">
                  <div className="product-img">
                    <h3 className="product-heading">Ballistic Helmet Accessories</h3>
                    <img src="/images/Helmet_Accessories.png" className="img-fluid" alt="" />
                  </div>
                  <div className="product-hover">
                    <img src="/images/product-arrow.png" className="hover-img" />
                    <h3 className="product-hover-heading">
                      Ballistic Helmet Accessories
                    </h3>
                    <p>
                      For our Ballistic Helmets, we have a wide range of accessories to increase the capability and effectiveness, like- Side rails, Side Protection, Pads, NVG Mount, Light, Headphone, Goggles, Harness, Counter weight, etc.
                    </p>
                  </div>
                </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="text-center py">
            <div className="input-group justify-content-center" data-aos="zoom-in">
              <a
                href="#"
                className="btn know-more-btn emp collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
               
              >
                Explore More Products
                <span className="input-group-text">
                  <i className="fa-solid " />
                </span>
              </a>
            </div>
          </div>



     
      </div></section>
    
   
  </div>
  )
}

export default AboutUs
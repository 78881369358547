import React from 'react'
import Slider from "react-slick";
import { useEffect, useState, useRef } from "react";
import { useParams,Link } from 'react-router-dom';
import { API_URL,BASE_URL, WEB_MAIN_URL } from "../utils/utility";
import axios from 'axios'
import { get, useForm } from "react-hook-form";
import { Modal, Button, Form } from "react-bootstrap";
import HelmetExport, { Helmet } from 'react-helmet';

function ProductDetail({slug}) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    
    const slider1 = useRef(null);
    const slider2 = useRef(null);
    useEffect(() => {
      setNav1(slider1.current);
      setNav2(slider2.current);
      // getData(slug)
      console.log(slug)
      getData(slug)
    }, []);

    const [product, setProduct] =  useState([]);
    const [selectedSize, setSelectedSize] =  useState([]);
    const [selectedColor, setSelectedColor] =  useState([]);
    
    const params = useParams();
    // const { id: slug } = params;
    const id  = slug;
     
     

    const canonical = "https://www.indianarmour.com/product/"+ slug;
     
    const banner = product?.banner ? (API_URL + "/" + product?.banner) :"/images/Front_Open_Molle_Webbing_Vest.png";
  

    const getData = async (id) => {
      try {
        const { data } = await axios.get(`${API_URL}/APIs/Product/index.php?page=${id}`  );
       
        setProduct(data[0])
    console.log(data[0])
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        
      }
    };

 



    //////////
    const [info, setInfo] = useState({
      type: "message",
      body: "",
    });

  const SaveData = async (END_POINT , body ) => {
    console.log(END_POINT, body);
    var response = await axios.get( END_POINT, body);
    console.log(response)
    return { data: response.data, message: response.message, status: response.status || response.statusCode, errors: response.errors };
  };

  const { register, handleSubmit, formState: { errors }} = useForm();
  const onSubmit = async(values) => {
    
    console.log(values)
   
 
    try {
      let apiRes = null;
    
  
  
        apiRes = await SaveData(`${API_URL}/APIs/Product/catalog_users.php?email=`+values.email, {
          ...values 
           
        });
     console.log(apiRes.status)
      if (apiRes.status == 200 && apiRes.data[0].Status == "True") {
        setInfo({ type: "message", body: "Form Submitted Successfully!" });
      //  setsubmitdisable(true)
      //  alert("Submit successfully")
      console.log(BASE_URL+"/"+product?.catalog_file)
       //////apiRes.catalog_file
      
       const newWindow = window.open(BASE_URL+"/"+product?.catalog_file, '_blank', 'noopener,noreferrer')
       if (newWindow) newWindow.opener = null
       let headers = new Headers();
     

  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');

  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('Access-Control-Allow-Credentials', 'true');

  headers.append('GET', 'POST', 'OPTIONS');

  // headers.append("Access-Control-Allow-Origin: *");

  // headers.append("Access-Control-Allow-Credentials: true ");

  // headers.append("Access-Control-Allow-Methods: OPTIONS, GET, POST");
  // headers.append("Access-Control-Allow-Headers: Content-Type, Depth, User-Agent, X-File-Size, X-Requested-With, If-Modified-Since, X-File-Name, Cache-Control");
// fetch(BASE_URL+"/"+product?.catalog_file, {
//   mode: 'no-cors',
  
//   method: 'GET',
//   headers: headers
// }).then(response => {
//   response.blob().then(blob => {
//       // Creating new object of PDF file
//       console.log(JSON.stringify(blob))
//       const fileURL = window.URL.createObjectURL(blob);
//       // Setting various property values
//       let alink = document.createElement('a');
//       alink.href = fileURL;
//       alink.download = product?.page_name +'.pdf';
//       alink.click();
//   })

//   handleClose()
// })
///////
      // navigate(`/Thankyou`);
      } else if(apiRes.data[0].Status == "False") {
        
        setInfo({ type: "error", body: "Something went wrong. Please try again!" });
        // throw new Error(apiRes.errors);
      }
    } catch (err) {
      setInfo({ type: "error", body: err.message });
    } 
    // finally {
    //   setApiStatus({ ...apiStatus, inProgress: false });
    // }
  
  };
    ////////
   
    
  return (
    <>

    <Helmet>
    <title>{product?.meta_title}</title>
    <meta name="description" content={product?.meta_desc} />
    <link rel="canonical" href={canonical} />
    </Helmet>
        <section className="inner-banner d-flex align-items-center " 
        style={{backgroundImage:"url("+banner +")"}}
        
        
        >
          <div className="inner-banner-content1">
            <div className="container">
              <h1 className="fw-bolder"  dangerouslySetInnerHTML={{__html: product?.banner_heading}}></h1>
            </div>
          </div>
        </section>
        <section className="pt-3  pb-5">
          <div className="container">
            <nav   >
              <ol className="breadcrumb mb-0 justify-content-start">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item"><Link to={WEB_MAIN_URL + product?.category}>{product?.category_breadcrumb}</Link></li>
                <li className="breadcrumb-item active" aria-current="page" >{product?.heading}</li>
              </ol>
            </nav>
          </div>
        </section>
        {/* Armed Vehicle */}
        <section className="armed-vehicle right-black-bg pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="armed-vehicle-slider">
                  <div className="slider-nav1">
                  <Slider  asNavFor={nav1}
        ref={slider2}
        slidesToShow={(product?.count_image)<=2 ? product?.count_image : 3}
        swipeToSlide={true}
        focusOnSelect={true}
        vertical= {true}
        //  centerMode= {true}
        //  centerPadding= {"0px"}
        >
                  {product?.image1 &&   <img src={API_URL + '/' + product?.image1} />}
                  {product?.image2 &&   <img src={API_URL + '/' + product?.image2} />}
                  {product?.image3 &&   <img src={API_URL + '/' + product?.image3} />}
                  {product?.image4 &&   <img src={API_URL + '/' + product?.image4} />}
                  {product?.image5 &&   <img src={API_URL + '/' + product?.image5} />}
                    
                    </Slider>
                  </div>
                  <div className="slider-for1">
                  <Slider asNavFor={nav2} ref={slider1} arrows={false}   vertical= {true} >
                   {product?.image1 &&   <img src={API_URL + '/' + product?.image1} />}
                  {product?.image2 &&   <img src={API_URL + '/' + product?.image2} />}
                  {product?.image3 &&   <img src={API_URL + '/' + product?.image3} />}
                  {product?.image4 &&   <img src={API_URL + '/' + product?.image4} />}
                  {product?.image5 &&   <img src={API_URL + '/' + product?.image5} /> }
                    </Slider>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="armed-vehicle-detail">
                  {/* <h2>Front Open Molle Webbing Vest</h2> */}
                  <h2 >{product?.heading}</h2>
                  {/* <h3>{product?.product_id}</h3> */}
                  <div className="vest-size">
                  {product?.size &&
                    
                    <> <strong>Size</strong>
                    <ul>

                    
                      
                      { product?.size?.split(',').map((s) => (
                     <li   
                   
                    //   onClick={() => setSelectedSize(s)}
                    //  className={selectedSize==s? "avest-active":""}
                     >{s}</li> 


                     ))}
                     </ul>
                  </>
                    }
                      {/* <li className="avest-active">M</li> */}
                       
                    
                  </div>
                  <div className="color-blk">
                  {product?.color &&

<>
                  <strong>Color</strong>
                  <ul className='color-list'>
                 

                    
                      
                      { product?.color?.split(',').map((c) => (
                        
                    <li 
                    
                    // onClick={() => setSelectedColor((c.split('+')[0]))}
                    style={{backgroundColor: c.split('+')[0] }}
                    className={selectedColor==c.split('+')[0]? "color-active":""}
                    ></li>

                    ))}
                     </ul>
                    </>
                    }
                    {/* <li style={{backgroundColor: "#000 "}}></li> */}
                    
                 
                </div>
                <div className="vehicle-btn">
                  <div className="download-catalog">

                    <Link to="/contact-us" className="btn know-more-btn submit send-enquiry">
                      Send Enquiry
                      <span className="input-group-text">
                        <img src="/images/arrow.svg" />
                      </span>
                    </Link>
                  </div>

                  {
                    product?.catalog_file && 
                 
                  <div className="download-catalog">
                    {/* <Link data-bs-toggle="modal" data-bs-target="#myModal">Download Catalog <img src="/images/arrow-box.svg" /></Link> */}
                    <Link onClick={handleShow}>Download Catalog <img src="/images/arrow-box.svg" /></Link>
                  </div>


                   }
                </div>
                 
                </div>
              </div>
            </div>
             
              
              
              
              </div></section>

              <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          Download Catalog
        </Modal.Header>
        <Modal.Body>
        {/* <button type="button" className="btn-close" data-bs-dismiss="modal"></button> */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 mt-3">
                   
                   <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email *"
                    {...register("email", { required: true })}
                  />
                   {errors.email && <span className='error'>Email Address is required</span>}
                </div>
                <button className="btn know-more-btn submit">
                    Submit
                    <span className="input-group-text">
                      <img src="/images/arrow.svg" />
                    </span>
                  </button>
              </form>
        </Modal.Body>
       
      </Modal>
              {/* <Modal className="modal" id="myModal" show={show} > */}
              {/* <div  className="modal" id="myModal" show={show} onHide={handleClose}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 mt-3">
                   
                   <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email *"
                    {...register("email", { required: true })}
                  />
                   {errors.email && <span className='error'>Email Address is required</span>}
                </div>
                <button className="btn know-more-btn submit">
                    Submit
                    <span className="input-group-text">
                      <img src="/images/arrow.svg" />
                    </span>
                  </button>
              </form>
            </div>



          </div>
        </div>
      </div> */}
      
      
      </>  
       
  )
}

export default ProductDetail
import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function TopSectionProduct() {
  
    
   
   const location = useLocation(); 
 const slug = location.pathname.split("/")[2];


 const [data, setData] = useState([]);
 const API = `https://www.indianarmour.com/indianarmourcms/APIs/Mainproduct/index.php?page_name=${slug}`;
 const ImageURL = 'https://www.indianarmour.com/indianarmourcms/';


 const fetchData = () => {
   fetch(API)
     .then((res) => res.json())
     .then((res) => {
       console.log( res)
       setData(res)
     })
 }
 useEffect(() => {
   fetchData()
 }, []);

  return (
    <>
     <Helmet>
        {/* <script src="js/vests.js"></script> */}
        <title>{data[0]?.meta_title}</title>
        <meta name="description" content={data[0]?.meta_desc} />
        <link rel="canonical" href={"https://www.indianarmour.com/" + slug} />

      </Helmet>
       <section className="inner-banner2 "  style={{ 
      backgroundImage: `url(${ImageURL + data[0]?.banner})` 
    }}>
        <div className="inner-banner2-content d-flex align-items-center">
          <div className="container">
          <h1 className=" fw-bolder"> {data[0]?.banner_heading}</h1>
          </div>
        </div>
      </section>
      <section className="breadcrumb-inner pt-3  pb-5">
        <div className="container">
          <nav>
            <ol className="breadcrumb mb-0 justify-content-start">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page"> {data[0]?.category_breadcrumb}</li>
            </ol>
          </nav>
        </div>
      </section>
      {/* banner */}
      <section className="ballistic-vesta-banner"  style={{ 
      backgroundImage: `url(${ImageURL + data[0]?.category_image})` 
    }} >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p0">
            <div className="ballistic-vesta-left">
              <div className="ballistic-vesta-text">
                {/* <h1>Ballistic Vests</h1> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="ballistic-vesta-right" data-aos="fade-left">
              <div className="ballistic-vesta-box">
              <Link to="https://www.indianarmour.com/bespoke-solutions">
                <ul>
                  <li className="vesta-box"><img src={ImageURL + data[0]?.image1} alt={data[0]?.image_1_alt_text}/></li>
                  <li dangerouslySetInnerHTML={{__html: data[0]?.image_1_alt_text}}></li>
                </ul>
                </Link>
              </div>
              <div className="ballistic-vesta-box">
              <Link to="/ballistic-products-customization-bulk-ordering" >
                <ul>
                <li className="vesta-box"><img src={ImageURL + data[0]?.image2} alt={data[0]?.image_2_alt_text}/></li>
                  <li dangerouslySetInnerHTML={{__html: data[0]?.image_2_alt_text}}></li>
                </ul>
                </Link>
              </div>
              <div className="ballistic-vesta-box">
                <ul>
                <li className="vesta-box"><img src={ImageURL + data[0]?.image3} alt={data[0]?.image_3_alt_text}/></li>
                  <li dangerouslySetInnerHTML={{__html: data[0]?.image_3_alt_text}}></li>
                </ul>
              </div>
              <div className="ballistic-vesta-box">
                <ul>
                <li className="vesta-box"><img src={ImageURL + data[0]?.image4} alt={data[0]?.image_4_alt_text}/></li>
                  <li dangerouslySetInnerHTML={{__html: data[0]?.image_4_alt_text}}></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      {/* banner */}
    </>
    
  )
}

export default TopSectionProduct
import React from 'react'

import ProductDetail from "../component/ProductDetail";
import ProductFAQ from '../component/ProductFAQ';
import {useLocation } from 'react-router-dom';
import TabData from '../component/TabData';
import KnowProduct from '../component/KnowProduct';
function Product() {
    
  const location = useLocation();
  const slug = location.pathname.split("/")[2];
 
// alert(slug)
  return (
    <>
      <ProductDetail slug={slug} />
      <TabData slug={slug} />
      <KnowProduct />
      <ProductFAQ slug={slug} />
      </>
  )
}

export default Product
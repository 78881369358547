import React from 'react'

import ProductDetail from "../../component/ProductDetail";
import ProductFAQ from '../../component/ProductFAQ';
import { useLocation } from 'react-router-dom';
import Slider from "react-slick";
import { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet"
import { Link } from 'react-router-dom'
import { BASE_URL } from '../../utils/utility';
import Certifcates from '../../component/Certifcates';
function PlatformProtection() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);


  const location = useLocation();

  const slug = location.pathname.split("/")[1];

  return (
    <div>
      <Helmet>
        <title>Indian Armour Platform Protection for Air Force, Navy & Land Vehicles </title>
        <meta name="description" content="Our superior platform protection solutions are designed for the Air Force, Navy, and Land Vehicles, providing trusted defense technology to ensure security and operational excellence." />
        <link rel="canonical" href="https://www.indianarmour.com/platform-protection" />
      </Helmet>
      <section className="inner-banner2 " style={{ backgroundImage: 'url("/images/platform-protection-banner.jpg")' }}>
        <div className="inner-banner2-content d-flex align-items-center">
          <div className="container">
            <h1 className=" fw-bolder">Platform Protection</h1>
          </div>
        </div>
      </section>
      <section className="breadcrumb-inner pt-3  pb-5">
        <div className="container">
          <nav >
            <ol className="breadcrumb mb-0 justify-content-start">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Platform Protection</li>
            </ol>
          </nav>
        </div>
      </section>

      <section className='pb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-left'>
              <h2 className='pb-2 font-55 smart-heading'>Smart Solutions for Platform Protection</h2>
              <p>At Indian Armour, we specialise in offering state-of-the-art intelligent solutions for vehicle, naval, and aircraft armour. We are dedicated to providing the highest level of safety and security for your valuable assets using our cutting-edge technologies and knowledge.</p>


            </div>
          </div>
        </div>
      </section>

      <div className="verticale-slider">


        {/* left right */}
        <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex={0}>
          <section className="vest-left-box section" id="scroll1">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                  <div className="vest-left-img vest-right-img" data-aos="fade-right" data-aos-offset={100}>
                    <img src="/images/land-systems.png" alt='armour vehicle protection' />
                  </div>
                </div>
                <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                  <div className="vest-box-set" data-aos="fade-left" data-aos-offset={100}>
                    <div className="vest-left-y-text">
                      <h2>Vehicle Armour</h2>
                      <h4><strong>Secure Your Ride With Armoured Confidence</strong></h4>
                      <p>We specialise in offering a variety of advanced vehicle armour systems. It is essential to invest in strong and dependable armour given the growing risks that both personal and commercial vehicles must contend with. Our team of professionals works directly with clients to comprehend their particular demands and provide solutions that are specifically tailored to fit those objectives. Our vehicle armour solutions range from private automobiles to business fleets, armoured trucks, and SUVs, and are made to withstand a variety of ballistic and explosive threats. You can rely on Indian Armour to offer you the best possible protection for your valuable assets.</p>
                      <div className="input-group mt-5">
                        <Link to="/product/armoured-vehicle" className="btn know-more-btn submit">View Product
                          <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* left right */}
          {/* left right */}
          <section className="vest-left-box vest-left-box2 section" id="scroll2">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                  <div className="vest-box-set-left" data-aos="fade-right" data-aos-offset={100}>
                    <div className="vest-left-y-text">
                      <h2>Naval Armour</h2>
                      <h4> <strong>Navigate Threats With Advanced Solutions</strong></h4>
                      <p>Protecting naval vessels is crucial when it comes to naval operations. Modern naval armour solutions that Indian Armour offers increase the capabilities of naval fleets to withstand contemporary threats. Our capabilities include designing and integrating armour systems that guarantee the security of the crew and essential equipment on board. Our naval armour solutions are specifically designed to offer unrivalled protection since we have a thorough awareness of the increasing security problems encountered by maritime forces. Count on Indian Armour to protect your naval resources and give you the confidence to carry out your missions.</p>
                      <div className="input-group mt-5">
                        <Link to="/product/naval-protection" className="btn know-more-btn submit">View Product
                          <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                  <div className="vest-left-img" data-aos="fade-left" data-aos-offset={100}>
                    <img src="/images/naval-systems.png" alt='naval armour' className='img-fluid' />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* left right */}
          {/* left right */}

          <section className="vest-left-box section" id="scroll3">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                  <div className="vest-left-img vest-right-img" data-aos="fade-right" data-aos-offset={100}>
                    <img src="/images/airborne-systems.png" className='img-fluid' alt='aircraft armour' />
                  </div>
                </div>
                <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                  <div className="vest-box-set" data-aos="fade-left" data-aos-offset={100}>
                    <div className="vest-left-y-text">
                      <h2>Aircraft Armour</h2>
                      <h4><strong>Conquer The Airspace For Aerial Supremacy</strong></h4>
                      <p>Having adequate armour systems for aircraft is crucial in a time when aerial threats are growing more sophisticated. Modern aircraft protection options from Indian Armour are strong yet lightweight. Our solutions are built to withstand a variety of dangers and offer improved protection for personnel and aircraft during crucial operations. Because we recognise how important it is to reduce weight while keeping maximum strength, our aircraft armour solutions are created using advanced materials and state-of-the-art engineering methods. Indian Armour provides specialised aircraft coatings in addition to our aircraft armour solutions. These coatings give aircraft surfaces an additional layer of defence against corrosion, abrasion, and environmental factors. You can assure the safety and robustness of your aircraft in difficult circumstances using Indian Armour's modern solutions.</p>
                      <div className="input-group mt-5">
                        <Link to="/product/air-protection" className="btn know-more-btn submit">View Product
                          <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>




        <section className="nij-levels pt-5">
          <div className="container">
            <div className='nij-levels-he mb-2 text-left'>
              <h2>Why Choose Indian Armour</h2>
              <h4 className='mb-4'><strong>Your One-Stop Shop For Platform Protection</strong></h4>
              <p>Indian Armour is an ideal choice when it comes to platform protection systems. Delivering smart solutions that ensure the greatest level of security and defence for your valuable assets is our area of expertise. We continuously conduct research and develop cutting-edge armour systems that can fend off contemporary dangers in order to stay at the top of technology. Using a customised strategy, we adapt our solutions to your unique needs, guaranteeing a perfect fit. Our continuous dedication to quality and dependability results in the delivery of robust and efficient armour solutions. We approach every project with a wealth of expertise and experience thanks to our qualified team of professionals. You can rely on Indian Armour for platform protection systems that are unmatched and well beyond expectations.</p>
            </div>
            {/* Certificates */}
            <Certifcates></Certifcates>
            {/* Certificates */}
          </div>
        </section>

        {/* faq */}
        <section className="faq-sec">
          <div className="container">
            <div className="faq-he"><h2>Frequently asked questions</h2></div>
            <div className="row">
              <div className="faq-accordion">
                <div className="row">
                  <div className="col-lg-6" >
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            What is platform protection?
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{}}>
                          <div className="accordion-body">
                            <p>Platform protection refers to the steps taken to secure various types of platforms, including vehicles, aircraft, ships, and other crucial assets, from dangers like explosive devices, ballistic strikes, and other forms of physical damage..</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Why is platform protection important?
                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>
                              Platform protection is essential to guaranteeing the security and safety of personnel, important infrastructure, and valuable assets. It increases a platform's ability to survive in dangerous situations and reduces possible dangers, minimising damage and increasing operational effectiveness.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading3">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseTwo">
                            What types of platforms can benefit from platform protection?
                          </button>
                        </h2>
                        <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>
                              A wide variety of platforms, including but not limited to military vehicles, armoured trucks, commercial ships, naval vessels, submarines, helicopters, fighter planes, and other high-value assets that may encounter diverse security challenges, can benefit from platform protection.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{/* /col-lg-6 */}
                  <div className="col-lg-6">
                    <div className="accordion" id="accordionExample1">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne1-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1-1" aria-expanded="true" aria-controls="collapseOne1-1">
                            Can platform protection systems be customised to specific requirements?
                          </button>
                        </h2>
                        <div id="collapseOne1-1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                              Yes, platform protection systems can be tailored to fit particular needs. Different platforms have different traits and are exposed to various risks. A customised strategy enables the application of suitable technologies and solutions that address the unique requirements and difficulties of each platform.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo1-2">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-2" aria-expanded="false" aria-controls="collapseTwo1-2">
                            Does platform protection require ongoing maintenance and support?
                          </button>
                        </h2>
                        <div id="collapseTwo1-2" className="accordion-collapse collapse" aria-labelledby="headingTwo-2" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                              In order to maintain their effectiveness, platform protection systems frequently need ongoing maintenance and assistance. To maintain the integrity and dependability of the protective systems, routine inspections, maintenance checks, and necessary upgrades or repairs are carried out.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo1-3">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-3" aria-expanded="false" aria-controls="collapseTwo1-3">
                            Can platform protection systems be integrated into existing platforms?
                          </button>
                        </h2>
                        <div id="collapseTwo1-3" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                          <div className="accordion-body">
                            <p>
                              Depending on the design and structural considerations, platform protection systems can be implemented into already-existing platforms. To assess the viability of integration, the platform's characteristics and compatibility with the needed security measures are evaluated. The specific needs of each platform can be accommodated by designing customised solutions.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{/* /col-lg-6 */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>




    </div>
  )
}

export default PlatformProtection
import React from 'react'
import { Link } from 'react-router-dom'

function Certifcates() {
  return (
    <>
     <div className="Certificates pt-5">
              <div className="row g-0 " >
                <div className="col-lg-4 col-sm-4">
                  <ul className="nav nav-pills flex-column" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a className="nav-link active" data-bs-toggle="pill" href="#home" aria-selected="false" role="tab" tabIndex={-1}>
                        <div className="sps-icon">
                          <img className="tapicon1" src="/images/tabicon-1.png" /><img className="tapicon2" src="/images/tabicon-1w.png" /> <span>Our Certificates</span>
                        </div>
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link " data-bs-toggle="pill" href="#menu-certificate1" aria-selected="true" role="tab">
                        <div className="sps-icon">
                          <img className="tapicon1" src="/images/tabicon-2.png" /><img className="tapicon2" src="/images/tabicon-2w.png" /><span>Our Strengths</span>
                        </div>
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" data-bs-toggle="pill" href="#menu-certificate2" aria-selected="false" tabIndex={-1} role="tab">
                        <div className="sps-icon">
                          <img className="tapicon1" src="/images/tabicon-3.png" /><img className="tapicon2" src="/images/tabicon-3w.png" /> <span>Our Machinery</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-8  col-sm-8 border">
                  <div className="tab-content">
                    <div className="tab-pane container fade active show" id="home" role="tabpanel">
                      <div className="Certificates-text">
                        <ul>
                          <li>Indian Armour products are  NIJ Standards and are listed on the NIJ website as a certified and approved supplier <Link to="https://cjtec.org/" target='_blank'>www.justnet.org</Link> </li>
                          <li>Our products are tested in both field-level and lab-level environments</li>
                          <li>Lab-testing occurs in various laboratories in India,  the US, UK and EU regions</li>
                        </ul>
                        <div className="certified-img">
                          <img src="/images/certificates-1.png" />
                          <img src="/images/certificates-2.png" />
                          <img src="/images/certificates-3.png" />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane container fade" id="menu-certificate1" role="tabpanel">
                      <div className="Certificates-text">
                        <ul>
                          <li>20 years of ballistic manufacturing experience</li>
                          <li>Patented and Trademarked Technologies</li>
                          <li>World’s most vertically integrated manufacturer from yarn to finished products</li>
                        </ul>
                       
                      </div>
                    </div>
                    <div className="tab-pane container fade" id="menu-certificate2" role="tabpanel">
                      <div className="Certificates-text">
                        <ul>
                          <li>Weaving capacity for 80,000 sqm per month</li>
                          <li>150+ sewing systems and capability for ultrasonic welding</li>
                          <li>Up to 10,000 helmets and 10,000 vests a month</li>
                          <li>Various testing machines</li>
                        </ul>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </>
  )
}

export default Certifcates
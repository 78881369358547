import React from 'react'
import Slider from "react-slick";
import { useEffect, useState, useRef } from "react";
import Certifcates from '../../component/Certifcates';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TopSection from '../../component/TopSection';
function BallisticHelmet() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
      autoplaySpeed: 3000,
  };
  // const [nav1, setNav1] = useState();
  // const [nav2, setNav2] = useState();
  // const slider1 = useRef(null);
  // const slider2 = useRef(null);
  // useEffect(() => {
  //   setNav1(slider1.current);
  //   setNav2(slider2.current);
  // }, []);

  const location = useLocation();
  const slug = location.pathname.split("/")[1];
  console.log(slug)
  const [cat, setCat] = useState([])
  const API = `https://www.indianarmour.com/indianarmourcms/APIs/Product/index.php?category=${slug}`;
  const ImageURL = 'https://www.indianarmour.com/indianarmourcms/';
  const fetchPost = () => {
    fetch(API)
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        setCat(res)
      })
  }
  useEffect(() => {
    fetchPost()
  }, [])
  return (
    <div>
     <TopSection/>
      {/* Mich Ach Helmet with Accessories */}
      <section className="helmets-acces">
        <div className="container">
          <div className="helmets-acces-slider">
            <div className="slider-fors" data-aos="fade-up" data-aos-duration={1500}>
            <Slider {...settings} >
                {cat.map((item) => {
                  return (
                    <div>
                      <div className="helmets-acces-bg" style={{ backgroundImage: 'url("/images/Helmet-bg.png")' }}>
                        <div className="row">
                          <div className="col-lg-5">
                            <div className="helmets-img">
                              <img src={ImageURL + item.category_image} />
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="helmets-acce-text">
                              <h2>{item.heading}</h2>
                              <p>{item.category_content}</p>
                              <Link to={`../` + item.category_slug} className="know-more ">Read more <img src="/images/arrow.svg" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}


              </Slider>
            </div>

            {/* <div className="slider-navs" data-aos="fade-up" data-aos-duration={1000}>
              <Slider asNavFor={nav1}
                ref={slider2}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0"
              >

                {cat.map((item) => {
                  return (
                    <div>
                      <div className="helmet-sli">
                        <div className="fs-detail">
                          <img src={ImageURL + item.category_image} />
                        </div>
                      </div>
                    </div>
                  )
                })}



              </Slider>
            </div> */}

          </div>
        </div>
      </section>
      <section className="view-all-product">
          <div className="container">
            <div className="row">
              <div className="view-all-btn">
                <div className="input-group mb-5">
                  <a href={"ProductList/"+slug} className="btn know-more-btn submit">View All Products
                    <span className="input-group-text"><img src="/images/arrow.svg" /></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      {/* Mich Ach Helmet with Accessories */}
      {/* Ballistic Helmets by Indian Armour */}
      <section className="helmets-in-armour" style={{ backgroundImage: 'url("/images/BG-.jpg")' }}>
        <div className="container">
          <div className="helmets-in-he">
            <h2>Ballistic Helmets by Indian Armour</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="helmet-in-text" data-aos="fade-right">
                <p>Our <strong> ballistic helmets</strong> have been specifically designed for use by civil police, military, SWAT, and Special Forces officers. These are also essential for use by law enforcement agencies, para-military forces, federal agencies, first responders as well as media personnel. With an impressive clientele over Europe, Africa, Asia, and the USA, we guarantee you the best performing ballistic helmets at highly competitive prices. These <Link to="/">ballistic helmets</Link> are lightweight and ergonomically designed to be comfortable for long-time wear preventing operational fatigue. High tenacity ballistic grade fibers such as DuPont™ Kevlar®, Teijin Aramid™ Twaron®, and even UHMWPE are used in multiple layers to ensure superior <strong>head protection</strong>. These ballistic headgears have also been engineered to protect against fragments and shrapnel that can prove to be fatal to the users. A high level of shock and impact absorption makes our helmets one of the highest performing ranges of ballistic helmets in the market.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="helmet-in-img" data-aos="fade-left">
                <img src="/images/ballastic-helmet.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Ballistic Helmets by Indian Armour */}
      {/* tabint */}
      {/* tabint */}
      <section className="new-tabing">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="new-tabing-box1" data-aos="zoom-in">
                <p>With over 2 decades of experience in manufacturing advanced combat &amp; tactical helmets, we have developed our products to provide complete satisfaction to our customers. Our head protection gears are of top-notch quality and the helmet profile is minimal, perfect for tactical and stealth situations.</p><p>
                </p>
                <p> Our ballistic headgears are made with advanced composite materials and are built to be durable and reliable, offering extreme comfort in combat zones. These premium bullet-resistant helmets are manufactured to the National Institute of Justice (NIJ) Level IIIA standards and are available in MICH, PASGT, and Special Force designs. We can also offer versions that are in full compliance with the MIL-STD set by the Department of Defense (DOD).</p>
              </div>
            </div>
          </div>
          <div className="new-tabing-box2">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div className="new-tabing-box2-img ballistic-armour-box-img" data-aos="fade-right">
                  <img src="/images/helmat-faq.png" />
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <div className="new-tabing-box2-text" data-aos="fade-left">
                  <div className="accordion" id="accordionExample1">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne1-1">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1-1" aria-expanded="true" aria-controls="collapseOne1-1">
                          Tactical Helmet, The latest advancements in relation to its materials
                        </button>
                      </h2>
                      <div id="collapseOne1-1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                            A Tactical helmet is designed to protect the wearer during tactical situations. These helmets were originally designed for the military, but since then they have made their way into law enforcement and other relevant services. Its application has managed to evolve over the previous century, from blocking shrapnel to stopping bullets.
                          </p>
                          <p> In the 1970s, Dupont™ launched Kevlar®, a lightweight fabric material that has now been regarded as protective armour. Teijin™ is yet another well-known manufacturer of ballistic fabric materials, featuring Twaron® fibres. Modern helmets come with a variety of padding solutions for added comfort, as well as a range of accessories and extensions. </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo1-2">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-2" aria-expanded="false" aria-controls="collapseTwo1-2">
                          Situations might necessitate the use of a ballistic helmet
                        </button>
                      </h2>
                      <div id="collapseTwo1-2" className="accordion-collapse collapse" aria-labelledby="headingTwo-2" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                            A ballistic helmet not only protects your delicate head, but also protects you from severe temperatures, fire, water, dampness, and ultraviolet (UV) radiation.
                          </p>
                          <p> Military, police forces, and other law enforcement agencies who are continuously exposed to potentially dangerous circumstances such as crossfires, riots, and wars are at a greater risk of injury and even death.</p>
                          <p>During natural disasters such as tornadoes and storms, internal or international attacks, rebellions, catastrophic events, and so on, a civilian will need to shield themselves from the worst calamities. Ballistic helmets can be considered a vital protective gear during rescue operations in such disasters.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo1-3">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-3" aria-expanded="false" aria-controls="collapseTwo1-3">
                          Military helmets. level of protection can a ballistic helmet offer
                        </button>
                      </h2>
                      <div id="collapseTwo1-3" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                            Ballistic helmets are designed to protect the wearer’s head from shrapnel, weapons such as artillery, bombs, rockets, hand-thrown grenades, satchel charges, and other explosives. Depending on the NIJ threat levels for which it is designed, ballistic helmets protect against different types of handguns. <br />Other blunt objects, such as the edge of a rifle, the swing of a handheld hammer, or a sharp blade, might seriously harm or kill the victim, but a tactical helmet will protect the user from such blunt force strikes and injuries.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo1-4">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-4" aria-expanded="false" aria-controls="collapseTwo1-4">
                          A perfect ballistic helmet for you
                        </button>
                      </h2>
                      <div id="collapseTwo1-4" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                          <p>
                            The perfect ballistic helmet protects your life while still allowing you to operate comfortably and spontaneously. The bulletproof helmets are not completely bulletproof, and the modern helmets offer varying degrees of protection depending on the NIJ level to which they are tested. A perfect ballistic helmet will free your hands by incorporating cameras, lights, and other features on the helmet based on your needs, protecting against head injuries such as collisions and providing a wide range of benefits such as protection against shrapnel, impacts from the edge of a rifle, and so on.
                          </p>
                          <p>Furthermore, the psychological impact of wearing a helmet should not be overlooked. Feeling safer and knowing what your advantages are could also enhance your confidence and help you focus better on the task at hand.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Certificates */}
          <Certifcates></Certifcates>
          {/* Certificates */}
        </div>
      </section>
      {/* tabint */}
      {/* faq */}
      <section className="faq-sec">
        <div className="container">
          <div className="faq-he">
            <h2>Frequently asked questions</h2>
          </div>
          <div className="row">
            <div className="faq-accordion">
              <div className="row">
                <div className="col-lg-6" data-aos="fade-right" data-aos-offset={300}>
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          Are there any Level 4 ballistic helmets?
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">
                          <p>    The L110 Combat IV ballistic helmet ATE Protection from the FAST series at ACH weight offers rifle bullet protection from 7.62 x 51 mm M80 NATO Ball round, V0 at 2200 fps.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Can a ballistic helmet stop a bullet?
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>
                            A ballistic helmet is designed to stop a bullet or projectile from penetrating your head.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading3">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseTwo">
                          What is a ballistic helmet used for?
                        </button>
                      </h2>
                      <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p>
                            A ballistic helmet is a standard infantry gear that provides ballistic protection from fragments and shrapnel to the head, temples, ear and neck of the wearer.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col-lg-6 */}
                <div className="col-lg-6" data-aos="fade-left" data-aos-offset={300}>
                  <div className="accordion" id="accordionExample3">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne1-1">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1-1" aria-expanded="true" aria-controls="collapseOne1-1">
                          Do Kevlar helmets expire?
                        </button>
                      </h2>
                      <div id="collapseOne1-1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                          <p>
                            Ballistic helmets and armour, in general, will degrade over time due to many different variables. The main factor affecting its life is how it is being used, how it is taken care of and the materials used in its manufacturing processes.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo1-2">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1-2" aria-expanded="false" aria-controls="collapseTwo1-2">
                          Is the helmet bulletproof?
                        </button>
                      </h2>
                      <div id="collapseTwo1-2" className="accordion-collapse collapse" aria-labelledby="headingTwo-2" data-bs-parent="#accordionExample3">
                        <div className="accordion-body">
                          <p>
                            No, there is no such thing as bulletproof helmets or any armour. There are bullet-resistant helmets and armour.
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                {/* /col-lg-6 */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default BallisticHelmet
import React from 'react'
import Slider from "react-slick";
import { useEffect, useState, useRef } from "react";
import { useParams,Link, useNavigate } from 'react-router-dom';
import { API_URL } from "../utils/utility";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { Modal, Button, Form } from "react-bootstrap";
import $ from 'jquery';
function MainFooter() {


  const navigate = useNavigate();

  //////////
  const [info, setInfo] = useState({
    type: "message",
    body: "",
  });

const SaveData = async (END_POINT , body ) => {
  console.log(END_POINT, body);
  var response = await axios.get( END_POINT, body);
  console.log(response)
  return { data: response.data, message: response.message, status: response.status || response.statusCode, errors: response.errors };
};

const { register, handleSubmit, formState: { errors },reset } = useForm();
const onSubmit = async(values) => {
  
  console.log(values)
  // this.preventDefault();
  // if (!checked) {
  //   return setInfo({
  //     body: "Please accept the terms and condition",
  //     type: "error",
  //   });
  // } else if (!checkedPrivacyPolicy) {
  //   return setInfo({
  //     body: "Please accept the Privacy Policy",
  //     type: "error",
  //   });
  // }
  // setInfo({ body: "", type: "message" });
   





  
  try {
    let apiRes = null;
  


      apiRes = await SaveData(`${API_URL}/APIs/Newslatter/index.php?email=`+values.email, {
        ...values 
         
      });
   console.log(apiRes.status)
    if (apiRes.status == 200 && apiRes.data[0].Status == "True") {
      setInfo({ type: "message", body: "Form Submitted Successfully!" });
    //  setsubmitdisable(true)
      //  alert("Thankyou for submission!")
       reset()
     navigate(`/Thankyou`);
    } else if(apiRes.data[0].Status == "False") {
      
      setInfo({ type: "error", body: "Something went wrong. Please try again!" });
      // throw new Error(apiRes.errors);
    }
  } catch (err) {
    setInfo({ type: "error", body: err.message });
  } 
  // finally {
  //   setApiStatus({ ...apiStatus, inProgress: false });
  // }

};


const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [textState, setTextState] = useState("Active");
const toggleText = () => {
  setTextState((state) => (state === "Active" ? "Inactive" : "Active"));
};


const [cookieAccepted, setCookieAccepted] = useState(localStorage.getItem('IndiaArmour') === 'yes');
const handleAccept = () => {
  setCookieAccepted(true);
  $(".cookie-modal").hide();
  $(".modal-backdrop").hide();
  $(".cookie-container").hide();
  localStorage.setItem('IndiaArmour', 'yes');
};

useEffect(() => {
  if (!cookieAccepted) {
    setTimeout(() => {     
      setCookieAccepted(localStorage.getItem('IndiaArmour') === 'yes');
    
    }, 1000);
  }
}, [cookieAccepted]);


  ////////
  return (
   <>
    <section className="upper-footer" >
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
          <div className="up-footer-1">
          <Link to="/"><img src="/images/logo-1.png" alt="" className="img-fluid"  width={84} height={74} loading="lazy"/></Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <div className="up-footer-1">
            <div className="img-ro">
              <img src="/images/phone2.svg"  width={36} height={36} loading="lazy"/>
            </div>
            <div className="ro-text">
              <p>Call Us</p>
              <a href="tel:+91-9891268600">+91-9891268600</a>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
          <div className="up-footer-1">
            <div className="img-ro">
              <img src="/images/mail.svg"  width={36} height={36}/>
            </div>
            <div className="ro-text">
              <p>Email</p>
              <a href="mailto:info@indianarmour.com">info@indianarmour.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer >
   
    <div className="container">
      <div className="row gx-5">
        <div className="col-lg-4">
          <div className="footer-col">
            <h4>Address</h4>
            <p className="d-flex align-items-center">
              <span className="address-icon">
                <img src="/images/map.svg"  width={36} height={36} loading="lazy"/>
              </span>
              <span className="address-content">
                Universal Lane, Village Dudhola, P O Bhagola Palwal,
                Haryana-121102, India
              </span>
            </p>
            <p className="d-flex align-items-center">
              <span className="address-icon">
                <img src="/images/phone.svg" width={25} height={25} loading="lazy"/>
              </span>{" "}
              <span className="address-content">
                <a href="tel:+91-9891268600">+91-9891268600,</a>
                <br />
                <a href="tel:+91-8750050215">+91-8750050215</a>
              </span>
            </p>
            <p className="d-flex align-items-center">
              <span className="address-icon">
                <img src="/images/envlop.svg" width={25} height={25} loading="lazy"/>
              </span>{" "}
              <span className="address-content">
                <a href="mailto:info@indianarmour.com">info@indianarmour.com</a>
              </span>
            </p>
          </div>
        </div>
        <div className="col-lg-2 col-sm-6">
          <div className="footer-col">
            <h4>Categories</h4>
            <ul className="footer-link">
              <li>
                <Link to="/ballistic-protection">Ballistic Protection</Link>
              </li>
            
              <li>
              <Link to="/ballistic-helmet">Ballistic Helmet</Link>
                
              </li>
              <li>
                <Link to="/ballistic-plates">Ballistic Plates</Link>
              </li>
              <li>
                 <Link to="/ballistic-vests">Ballistic Vest</Link>
              </li>
             
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-sm-6">
          <div className="footer-col">
            <h4>Resources</h4>
            <ul className="footer-link">
              
              <li>
                 <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/faq">FAQs</Link>
              </li>
              <li>
                <Link to="images/Indian_Armour_Brochure.pdf" target='_blank'>Brochure</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="footer-col">
            <h4>Signup for Newsletter</h4>
            <p>Enter your e-mail to sign up for our newsletter</p>
            <div className="newsletter">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 mt-3">
                   
                   <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email *"
                    {...register("email", { required: true })}
                  />
                   {errors.email && <span className='error'>Email Address is required</span>}
                </div>
                <div className="input-group">
                <button className="btn know-more-btn submit">
                    Submit
                    <span className="input-group-text">
                      <img src="/images/arrow.svg"  width={15} height={10} loading="lazy"/>
                    </span>
                  </button>
                  </div>
              </form>
              
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="social-media d-flex align-items-center">
            <span>Follow Us</span>
            <ul className="d-flex list-inline m-0">
              <li>
                <Link to="https://www.facebook.com/indianarmoursystems/ " target='_blank'>
                  <i className="fa-brands fa-facebook-f" />
                </Link>
              </li>
              <li>
                <Link to="https://www.linkedin.com/company/indian-armour-systems-private-limited" target='_blank'>
                  <i className="fa-brands fa-linkedin-in" />
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/armour_indian" target='_blank' className='twitter'>
               <img src="/images/twitter.png" alt="" className='twitter-icon white-twiiter'/>
               <img src="/images/twitter-red.png" alt="" className='twitter-icon red'/>
                </Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/indian_armour_systems/" target='_blank'>
                  <i className="fa-brands fa-instagram" />
                </Link>
              </li>
              <li>
                <Link to="https://www.youtube.com/channel/UCXTbVLwSlwaSKsQoRV2E1RA" target='_blank'>
                  <i className="fa-brands fa-youtube" />
                </Link>
              </li>
            </ul>
            <Link to="https://api.whatsapp.com/send?phone=911275297251&text=Hello" className='whatspp' target='_blank'><img src='/images/whatsapp.png' width={50} height={50} loading="lazy"/></Link>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div className="bottom-strip">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center d-block">
            Copyright © 2024. Indian Armour Systems   <span>|</span> <Link to="/faq">FAQs</Link> <span>|</span>{" "}
            <Link to="/privacy-policy">Privacy Policy</Link> <span>|</span>{" "}
            <Link to="/certification">Certifications </Link> <span>|</span> 
            <Link to="/bespoke-solutions"> Bespoke-Solutions</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
<div className={`cookie-container  ${!cookieAccepted ? 'active' : ''}`}>
  <div className={`use-cookie ${!cookieAccepted ? 'active' : ''}`}>
    <h3>We use cookies</h3>
    <p>We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from.</p>
    <div className='cookie-btn-blk'>
    <Link className='ok-btn' onClick={handleAccept}>Ok</Link>
     <Link className='prefernce-btn' onClick={handleShow}>Change my preference</Link>
    </div>
  </div>
  </div>
<div className={`use-cookie ${!cookieAccepted ? 'active' : ''}`}>
  <Modal show={show} onHide={handleClose}  size="lg"  centered className='cookie-modal' >
        <Modal.Header closeButton>
        
          <Modal.Title>  <span>Indian Armour</span> Cookies Preferences Center</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            
          <div className='cookie-modal-left'>
          <ul className="nav nav-pills flex-column">
    <li className="nav-item">
      <a className="nav-link active" data-bs-toggle="pill" href="#cookie-tab-1">
      Your privacy
      </a>
    </li>
    <li className="nav-item">
      <a className="nav-link" data-bs-toggle="pill" href="#cookie-tab-2">
      Strictly necessary cookies
      </a>
    </li>
    <li className="nav-item">
      <a className="nav-link" data-bs-toggle="pill" href="#cookie-tab-3">
      Functionality cookies
      </a>
    </li>
    <li className="nav-item">
      <a className="nav-link" data-bs-toggle="pill" href="#cookie-tab-4">
      Tracking cookies
      </a>
    </li>
    <li className="nav-item">
      <a className="nav-link" data-bs-toggle="pill" href="#cookie-tab-5">
      Targeting and advertising cookies
      </a>
    </li>
    <li className="nav-item">
      <a className="nav-link" data-bs-toggle="pill" href="#cookie-tab-6">
      More information
      </a>
    </li>
  </ul>
          </div>
          <div className='cookie-modal-right'>
          <div className="tab-content">
    <div className="tab-pane container active" id="cookie-tab-1">
      <h4>Your privacy is important to us</h4>
      <p>Cookies are very small text files that are stored on your computer when you visit a website. We use cookies for a variety of purposes and to enhance your online experience on our website (for example, to remember your account login details).</p>
      <p>You can change your preferences and decline certain types of cookies to be stored on your computer while browsing our website. You can also remove any cookies already stored on your computer, but keep in mind that deleting cookies may prevent you from using parts of our website.</p>
    </div>
    <div className="tab-pane container fade" id="cookie-tab-2">
      <h4>Strictly necessary cookies</h4>
      <p>These cookies are essential to provide you with services available through our website and to enable you to use certain features of our website.</p>
      <p>Without these cookies, we cannot provide you certain services on our website.</p>
      <div className="form-check form-switch">
  <input
    className="form-check-input"
    type="checkbox"
    role="switch"
    id="flexSwitchCheckDisabled"
    disabled="disabled"
    defaultChecked="checked"
  />
  <label className="form-check-label" htmlFor="flexSwitchCheckDisabled">
  Always active
  </label>
</div>

    </div>
    <div className="tab-pane container fade" id="cookie-tab-3">
      <h4>Functionality cookies</h4>
      <p>These cookies are used to provide you with a more personalized experience on our website and to remember choices you make when you use our website.</p>
      <p>For example, we may use functionality cookies to remember your language preferences or remember your login details.</p>
      <div className="form-check form-switch">
      <input
    className="form-check-input"
    type="checkbox"
    role="switch"
    id="flexSwitchCheckChecked"
    defaultChecked="checked"
    onClick={toggleText}
  />
  <label className="form-check-label" htmlFor="flexSwitchCheckChecked"  >
  {textState}
  </label>
</div>

    </div>


    <div className="tab-pane container fade" id="cookie-tab-4">
      <h4>Tracking cookies</h4>
      <p>These cookies are used to collect information to analyze the traffic to our website and how visitors are using our website.</p>
      <p>For example, these cookies may track things such as how long you spend on the website or the pages you visit which helps us to understand how we can improve our website for you.</p>
      <p>The information collected through these tracking and performance cookies do not identify any individual visitor.</p>
      <div className="form-check form-switch">
      <input
    className="form-check-input"
    type="checkbox"
    role="switch"
    id="flexSwitchCheckChecked"
    defaultChecked="checked"
    onClick={toggleText}
  />
  <label className="form-check-label" htmlFor="flexSwitchCheckChecked"  >
  {textState}
  </label>
</div>
    </div>


    <div className="tab-pane container fade" id="cookie-tab-5">
      <h4>Targeting and advertising cookies</h4>
      <p>These cookies are used to show advertising that is likely to be of interest to you based on your browsing habits.</p>
      <p>These cookies, as served by our content and/or advertising providers, may combine information they collected from our website with other information they have independently collected relating to your web browser's activities across their network of websites.</p>
      <p>If you choose to remove or disable these targeting or advertising cookies, you will still see adverts but they may not be relevant to you.</p>
      <div className="form-check form-switch">
      <input
    className="form-check-input"
    type="checkbox"
    role="switch"
    id="flexSwitchCheckChecked"
    defaultChecked="checked"
    onClick={toggleText}
  />
  <label className="form-check-label" htmlFor="flexSwitchCheckChecked"  >
  {textState}
  </label>
</div>
    </div>
    <div className="tab-pane container fade" id="cookie-tab-6">
      <h4>More information</h4>
      <p>For any queries in relation to our policy on cookies and your choices, please contact us.</p>
    </div>
  </div>
          </div>
        
  {/* Tab panes */}
  
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAccept}  id='save-preference'>
          Save my preferences
          </Button>
         
        </Modal.Footer>
      </Modal>
      </div>
</>

  )
}

export default MainFooter
import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Blog() {
  const [post, setPost] = useState([]);
  const API = 'https://www.indianarmour.com/indianarmourcms/APIs/Blog/';
  const ImageURL = 'https://www.indianarmour.com/indianarmourcms/';

 
  const fetchPost = () => {
    fetch(API)
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        setPost(res)
      })
  }
  useEffect(() => {
    fetchPost()
  }, [])


  const location = useLocation();
  const slug = location.pathname.split("/")[1];
 

  return (
    <>
    <Helmet>
        <title>Explore Latest Insights and Expertise | Indian Armour Blogs  </title>
        <meta name="description" content="Dive into the latest articles and thought leadership on Indian Armour System’s Blog. Discover valuable insights, industry trends, and expert perspectives. Explore the world of Body Armour through the lens of our expertise." />
        <link rel="canonical" href="https://www.indianarmour.com/blog" />
      </Helmet>
    <section
      className="inner-banner d-flex align-items-center "
      style={{ backgroundImage: 'url("/images/blog-banner.jpg")' }}
    >
      <div className="inner-banner-content1">
        <div className="container">
          <h1 className="text-white fw-bolder">Blogs</h1>
        </div>
      </div>
    </section>

   

    <section className="breadcrumb-inner pt-3  pb-5">
      <div className="container">
        <nav>
          <ol className="breadcrumb mb-0 justify-content-start">
            <li className="breadcrumb-item">
             <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Blog
            </li>
          </ol>
        </nav>
      </div>
    </section>
    {/* <section className="blog-col-6 right-black-bg">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 col-sm-6"
            data-aos="fade-up"
            data-aos-duration="300ms"
          >
            <div className="blog-6-list position-relative overflow-hidden">
              <div className="blog-6-list-img">
                <img src="/images/blog1.jpg" className="img-fluid w-100" />
              </div>
              <div className="blog-6-content d-flex position-absolute bottom-0 left-0 w-100">
                <div className="b-6-left">
                  <h4>Understanding the Highest Level of Body Armor</h4>
                  <span>June 12, 2022</span>
                </div>
                <div className="b-6-right">
                  <a href="blog-detail.php" className="blog-6-btn">
                    <i className="fa-solid fa-arrow-right-long" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-sm-6"
            data-aos="fade-up"
            data-aos-duration="600ms"
          >
            <div className="blog-6-list position-relative overflow-hidden">
              <img src="/images/blog2.jpg" className="img-fluid w-100" />
              <div className="blog-6-content d-flex position-absolute bottom-0 left-0 w-100">
                <div className="b-6-left">
                  <h4>Female Specific Body Armour</h4>
                  <span>June 12, 2022</span>
                </div>
                <div className="b-6-right">
                  <a href="blog-detail.php" className="blog-6-btn">
                    <i className="fa-solid fa-arrow-right-long" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    <section className="blog-3-blk mt-40 pb-100">
      <div className="container">
        <div className="row gy-4">
     
        {/* {post[0].id} */}
       {post.map((item, i) => (
          
             <>
             
            { i == 0 || i== 1 
            ?
            
             
    
          <div
            className="col-lg-6 col-sm-6"
            data-aos="fade-up"
            data-aos-duration="300ms"
          >
            <div className="blog-6-list position-relative overflow-hidden">
              <div className="blog-6-list-img">
                <img src={ImageURL+item.image} className="img-fluid w-100" alt={item.banner_alt_text}/>
              </div>
              <div className="blog-6-content d-flex position-absolute bottom-0 left-0 w-100">
                <div className="b-6-left">
                  <h4>{item.title}</h4>
                  <span>{item.publish_date}</span>
                </div>
                <div className="b-6-right">
                  
                  <a href={"../" + item.slug} className="blog-6-btn">
                  Read more <i className="fa-solid fa-arrow-right-long" />
                </a>
                </div>
              </div>
            </div>
          </div>
         
    
            
            :
             
            <div
            className="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration="900ms"
            key={i}
          >
            <div className="blog-blk-list">
              <div className="card-img">
                <img src={ImageURL+item.image} className="img-fluid w-100" alt={item.banner_alt_text}/>
              </div>
              <div className="blog-blk-content">
                <h4>
                {item.title}
                </h4>
                <a href={"../" + item.slug} className="know-more ">
                  Read more <img src="/images/arrow.svg" />
                </a>
              </div>
            </div>
          </div>
            }
          
            </>
          
          
          ))}

          
          {/* col-lg-4 */}



         
        </div>
      </div>
    </section>


    
  </>
  )
}

export default Blog